import {Grid, TextField} from "@material-ui/core";
import {Trans, useTranslation} from "react-i18next";
import Typography from "@material-ui/core/Typography";
import React, {useState} from "react";
import {makeStyles} from "@material-ui/core/styles";
import iamService from "../../services/iamService";
import rfqService from "../../services/rfqService";
import LoadingButton from "../../components/LoadingButton";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%',
  },
  contentWrapper: {
    padding: '10% 80px',
    [theme.breakpoints.down('sm')]: {
      padding: '80px 50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '50px 20px',
    },
  },
  title: {
    width: '640px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '450px',
    },
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  mainContent: {
    marginTop: '30px',
    width: '360px',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
    },
  },
  item: {
    width: '100%',
    '& button': {
      width: '100%',
    }
  },
  terms: {
    color: theme.currentTheme.colors[3],
  },
  dividerLine: {
    borderTop: 'solid 1px #cdcdcd',
    width: 'calc(50% - 40px)',
    height: '5px',
    display: 'inline-block'
  },
  dividerText: {
    width: '80px',
    textAlign: 'center',
    display: 'inline-block'
  }
}));

const EmailRegex = /^[-!#$%&\'*+\/0-9=?A-Z^_a-z{|}~](\.?[-!#$%&\'*+\/0-9=?A-Z^_a-z`{|}~])*@[a-zA-Z0-9](-*\.?[a-zA-Z0-9])*\.[a-zA-Z](-?[a-zA-Z0-9])+$/;

export default function EmailVerifySection(props) {
  const classes = useStyles();
  const {t} = useTranslation();
  const [emailInfo, setEmailInfo] = useState('');
  const [emailValue, setEmailValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleEmailChange = function (value) {
    if (value && value.trim()) {
      setEmailValue(value);
    }
    setEmailInfo('');
  }

  const checkRegex = function (email) {
    let valid = true;
    const strongRegex = new RegExp(EmailRegex)
    if (!strongRegex.test(email)) {
      valid = false;
    }
    return valid;
  }

  const verifyEmail = () => {
    if (!emailValue || !emailValue.trim()) {
      setEmailInfo('Please input an email');
      return;
    }
    if (!checkRegex(emailValue)) {
      setEmailInfo('Not a valid email');
      return;
    }
    setEmailInfo('');
    setIsLoading(true);
    iamService.getUser(emailValue).then((data) => {
      if (data) {
        setEmailInfo('Incomplete email address, try again.');
        setIsLoading(false);
      } else {

        rfqService.checkSignUpEmail(emailValue).then((checkResult) => {

          if (checkResult && checkResult.data && checkResult.data.status === 200) {
            setIsLoading(false);
            props.onContinue(emailValue);
          } else {
            setIsLoading(false);
            setEmailInfo('Incomplete email address, try again.');
          }

        }).catch((_) => {
          setIsLoading(false);
          setEmailInfo('Incomplete email address, try again.');
        });

      }
    })
  }

  return (
    <Grid container direction={'column'} alignItems={'center'} className={classes.contentWrapper}>
      <Grid item>
        <Typography variant={'h2'} className={classes.title}>{t('register.emailPageTitle')}</Typography>
      </Grid>

      <Grid container direction={'column'} alignItems={'center'}
            spacing={3} className={classes.mainContent}>
        <Grid item className={classes.item}>
          <TextField fullWidth
                     label={t('register.emailHolder')}
                     variant='outlined'
                     autoComplete='none'
                     className={classes.item}
                     error={!!emailInfo}
                     helperText={emailInfo}
                     onChange={(e) => {
                       handleEmailChange(e.target.value)
                     }}
          >
          </TextField>
        </Grid>
        <Grid item className={classes.item}>
          <LoadingButton variant="contained" color='secondary' onClick={verifyEmail} isLoading={isLoading}>
            {t('common.continue')}
          </LoadingButton>
        </Grid>
        <Grid item>
          <Typography variant={'body2'} className={classes.terms}>
            <Trans t={t} components={[<a href="/terms-privacy?type=terms" target="_blank"/>,
              <a href="/terms-privacy?type=privacy" target="_blank"/>]}>{t('register.emailPageHint')}</Trans>
          </Typography>
        </Grid>
      </Grid>
    </Grid>
  )
}
