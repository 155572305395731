import CommonLayout from "../../components/CommonLayout";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {Snackbar, Typography} from "@material-ui/core";
import FormFunction from "./FormFunction";
import React, {useState} from "react";
import PasswordInput from "../../components/PasswordInput";
import LoadingButton from "../../components/LoadingButton";
import iamService from "../../services/iamService";
import useToken from "../../hooks/useToken";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    padding: '80px',
    paddingTop: '20px',
    backgroundColor: theme.currentTheme.background,
    [theme.breakpoints.down('sm')]: {
      padding: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 20px 50px 20px',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title1: {
    fontSize: '36px',
    fontFamily: 'RobotoRegular',
    '@media (max-width:600px)': {
      fontSize: '22px',
    },
    fontWeight: '300',
    textAlign: 'center',
    marginBottom: '20px',
  },
  submitButton: {
    width: '100%',
    height: '48px !important',
    marginTop: '30px',
    fontFamily: 'RobotoRegular',
    fontSize: '16px',
  },
  alertTitle: {
    fontFamily: 'RobotoRegular',
    fontSize: '16px',
  },
  root: {
    width: '460px',
    '@media (max-width:600px)':  {
      width: '95%',
    },
  }

}));

export default function Security() {

  const { t } = useTranslation();
  let { token } = useToken();
  const classes = useStyles();

  const {fields, isFormValid, handleChange} = FormFunction();
  const [isLoading, setIsLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const changePassword = () => {
    setIsLoading(true);
    if (!isFormValid()) {
      setIsLoading(false);
      return;
    }
    iamService.changePassword(token.access_token, fields.oldPassword.value, fields.newPassword.value).then((result) => {
      console.log('changePassword: ', result);

      if (result.status && result.code) {

        if (result.code === 1000 && result.status === 200) {
          setOpenSuccess(true);
          setOpenError(false);
          setIsLoading(false);
          fields.oldPassword.value = '';
          fields.newPassword.value = '';
          fields.rePassword.value = '';

        } else if (result.code === 2704 && result.status === 400) {
          fields.oldPassword.valid = false;
          fields.oldPassword.errorMsg = 'Incorrect password';
          setIsLoading(false);
        } else {
          setOpenError(true);
          setIsLoading(false);
        }

      } else {
        setOpenError(true);
        setIsLoading(false);
      }

    });

  }

  const handleSuccessClose = () => {
    setOpenSuccess(false);
  }

  const handleErrorClose = () => {
    setOpenError(false);
  }

  return (

    <CommonLayout>
      <div className={classes.pageContainer}>
        <div className={classes.root}>

          <div className={classes.title1}>
            {t('security.changePassword')}
          </div>

          <div style={{marginTop: '30px'}}>
            <PasswordInput
              title={fields.oldPassword.title}
              value={fields.oldPassword.value}
              onChange={(e) => {
                handleChange(fields.oldPassword.name, e.target.value)
              }}
              initValue={fields.oldPassword}
              errorMsg={fields.oldPassword.errorMsg}
              valid={fields.oldPassword.valid}
            />
          </div>

          <div style={{marginTop: '20px'}}>
            <PasswordInput
              title={fields.newPassword.title}
              value={fields.newPassword.value}
              onChange={(e) => {
                handleChange(fields.newPassword.name, e.target.value)
              }}
              initValue={fields.newPassword}
              errorMsg={fields.newPassword.errorMsg}
              valid={fields.newPassword.valid}
            />
          </div>

          <div className={classes.hintsDiv}>
            <ul>
              <li><Typography variant="body2">{t('register.infoMsg2')}</Typography></li>
              <li><Typography variant="body2">{t('register.infoMsg3')}</Typography></li>
              <li><Typography variant="body2">{t('register.infoMsg4')}</Typography></li>
            </ul>
          </div>

          <div style={{marginTop: '20px'}}>
            <PasswordInput
              title={fields.rePassword.title}
              value={fields.rePassword.value}
              onChange={(e) => {
                handleChange(fields.rePassword.name, e.target.value)
              }}
              initValue={fields.rePassword}
              errorMsg={fields.rePassword.errorMsg}
              valid={fields.rePassword.valid}
            />
          </div>

          <LoadingButton className={classes.submitButton} isLoading={isLoading} onClick={changePassword}>
            {t('security.change')}
          </LoadingButton>

        </div>
      </div>

      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleSuccessClose}>
        <Alert onClose={handleSuccessClose} severity="success">
          <span className={classes.alertTitle}>{t('security.passwordChangedSuccess')}</span>
        </Alert>
      </Snackbar>

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error">
          <span className={classes.alertTitle}>{t('security.cannotChangePassword')}</span>
        </Alert>
      </Snackbar>

    </CommonLayout>


  );

}
