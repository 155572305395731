import {makeStyles} from "@material-ui/core/styles";
import {Button, IconButton} from "@material-ui/core";
import Toolbar from "@material-ui/core/Toolbar";
import OpenDaisLogo from "./OpenDaisLogo";
import MenuIcon from "@material-ui/icons/Menu";
import {useTranslation} from "react-i18next";
import {useState} from "react";
import Drawer from "@material-ui/core/Drawer";
import Box from "@material-ui/core/Box";
import ThemeToggle from "./ThemeToggle";

const drawerWidth = 280;
const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.currentTheme.backgrounds[0],
    height: '58px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconButton: {
    color: '#FFFFFF',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    height: '100%',
  },
  drawerPaper: {
    width: drawerWidth,
    height: '300px',
    backgroundColor: 'rgba(10, 25, 41)',
    marginTop: '56px',
  },
  drawerHeader: {
    height: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    padding: theme.spacing(0, 1),
    ...theme.mixins.toolbar,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  menuButton: {
    color: '#FFFFFF',
    fontSize: '16px',
    width: '100px'
  },
  hide: {
    display: 'none',
  },
  signBtn: {
    [theme.breakpoints.up('md')]: {
      width: '100px',
    },
  },
  signBtnTxt: {
    fontSize: '16px',
    fontFamily: 'RobotoRegular',
    letterSpacing: '0.08px',
    fontWeight: '600',
  }
}));
export default function MobileHeader(props) {
  const classes = useStyles();
  const {t} = useTranslation();
  const handlers = props.handlers;
  const darkMode = props.darkMode;
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const unsignedMenu = () => (
    <>
      <Button className={classes.menuButton} onClick={handlers.signInHandler}>
        <span style={{position: 'absolute', left: '10px'}}>{t('login.signIn')}</span>
      </Button>
      <Button className={classes.menuButton} onClick={handlers.signUpHandler}>
        <span style={{position: 'absolute', left: '10px'}}>{t('login.signUp')}</span>
      </Button>
    </>
  )

  const signedMenu = () => (
    <>
      <Button className={classes.menuButton} onClick={handlers.gotoProfilePage}>
        <span style={{position: 'absolute', left: '10px'}}>{t('toolbar.profile')}</span>
      </Button>
      <Button className={classes.menuButton} onClick={handlers.gotoSubscriptionPage}>
        <span style={{position: 'absolute', left: '10px'}}>{t('toolbar.subscription')}</span>
      </Button>
      <Button className={classes.menuButton} onClick={handlers.gotoSecurityPage}>
        <span style={{position: 'absolute', left: '10px'}}>{t('toolbar.security')}</span>
      </Button>
    </>
  )

  const rightDrawer = (anchor) => (
    <div
      className={classes.drawer}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}>
      <div className={classes.drawerHeader}>

        {props.token && signedMenu()}

        <Button className={classes.menuButton} onClick={() => handlers.jump2Solutions()}>
          <span style={{position: 'absolute', left: '10px'}}>{t('toolbar.solutions')}</span>
        </Button>
        <Button className={classes.menuButton} onClick={() => handlers.jump2Enterprise()}>
          <div style={{position: 'absolute', left: '10px'}}>{t('toolbar.enterprise')}</div>
        </Button>
        <Button className={classes.menuButton} onClick={() => handlers.jump2Download()}>
          <div style={{position: 'absolute', left: '10px'}}>{t('toolbar.download')}</div>
        </Button>
        {props.token && <Button className={classes.menuButton} onClick={() => handlers.jump2Pricing()}>
          <span style={{position: 'absolute', left: '10px'}}>{t('toolbar.pricing')}</span>
        </Button>}

        {!props.token && unsignedMenu()}

        {props.token && <Button className={classes.menuButton} onClick={handlers.signOutHandler}>
          <span style={{position: 'absolute', left: '10px'}}>{t('toolbar.logout')}</span>
        </Button>}

      </div>
    </div>
  );

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({...state, [anchor]: open});
  };

  return (
    <Toolbar className={classes.header}>
      <OpenDaisLogo logoClick={handlers.jump2Home} darkMode={darkMode}></OpenDaisLogo>

      <Box sx={{flexGrow: 1}}/>

      <ThemeToggle/>

      <IconButton
        style={{marginLeft: '10px'}}
        color="inherit"
        aria-label="open drawer"
        onClick={toggleDrawer("right", true)}
        edge="start">
        <MenuIcon/>
      </IconButton>

      <Drawer anchor={'right'}
              open={state['right']}
              classes={{
                paper: classes.drawerPaper,
              }}
              onClose={toggleDrawer('right', false)}>
        {rightDrawer('right')}
      </Drawer>
    </Toolbar>
  );
}
