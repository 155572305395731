import {JSEncrypt} from "jsencrypt";

const publicKey = '-----BEGIN PUBLIC KEY-----' +
    'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCXIjxzEbZWyU28OFYfhxM9omGcq' +
    '0SZW6dSsy49owlVEK61IXBNb706TWV+9DXgBLe5/tLwQE4raNasEdtQO26T3bAxaw' +
    'MenvXx99fa0M744oblOojJSmeB7Y1Y4/q1bYN6c3YYKV7QU4R6iAtjpws6IG9TV9M' +
    'XQrROnpj6vNqn4wIDAQAB' +
    '-----END PUBLIC KEY-----';

const privateKey = `-----BEGIN PRIVATE KEY-----
  MIICdgIBADANBgkqhkiG9w0BAQEFAASCAmAwggJcAgEAAoGBAJciPHMRtlbJTbw4Vh+HEz2iYZyrRJlbp1KzLj2jCVUQrrUhcE1vvTpNZX70NeAEt7n+0vBATito1qwR21A7bpPdsDFrAx6e9fH319rQzvjihuU6iMlKZ4HtjVjj+rVtg3pzdhgpXtBThHqIC2OnCzogb1NX0xdCtE6emPq82qfjAgMBAAECgYAnyNdo4yzAw9U2yw3A1un1u50+hMdWJ45wcsKlbxmxzuZuwBK0D8ocYuto9BiKlDNZPv+ARy1MuBhqy3iUMlD1KWkOJX9jhG+Gu5dCwnxR/ZaDh5iZnyUF3ixQQLehxWOQqeDw05/iuHGOWbItq/lLIB3bBwD2BLvcGQHKSVuyYQJBAPiZu5avz8aTStENZQExhRjiUdidSJpzpeqz0a0Wh866Ks1LbYzi0ane7ZCfu7rTxIGkKB5XNqnkn3wDc75WWCUCQQCbodVakIw4rJATSu4KCNG4EPExL+vjE+V16FS/O4MyFjSPxA9jbubbNFGA4T+acLEDn7u+BlahNjzsIIxZTR1nAkA5KTgN3Nz8fs+cI+VOkfFVmGxTT07rXJ4Qykny75RIf1vqGmbVFy44Te736xKCUcEEQheLamhCRjQ5kp2hbT8VAkAOQpzfCIGgq8cjzWjUSIyW1g6TmDWAABG1ZQ1m3FggHcLBLZDPZpkGmtug+IKwbwT5NQip18DWUrbwV+ckQ5mfAkEAjSbRVMSS+yq468inDjACRb5l96T5v1M5uHnTILTfAPsH2eJqiXgyScDvy/KLBycQWGl2+Dw3A+Dj4XMKLIVrIw==
  -----END PRIVATE KEY-----`;

const LocalEncrypt = (strData) => {
    console.log(strData);
    const encrypter = new JSEncrypt();
    encrypter.setPublicKey(publicKey);
    encrypter.setPrivateKey(privateKey);

    return encrypter.encrypt(strData);
}
export default LocalEncrypt;