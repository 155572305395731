const langEN = {
  lang: '中文',
  changeLang: "Translate to 中文(简体)",
  common: {
    ok: 'Ok',
    cancel: 'Cancel',
    save: 'Save',
    confirm: 'Confirm',
    actions: 'Actions',
    delete: 'Delete',
    edit: 'Edit',
    noRecord: 'No records to display',
    download: 'Download',
    check: 'Check',
    add: 'Add',
    home: 'Home',
    export: 'Export',
    exportAsCsv: 'Export as CSV',
    duplicate: 'Duplicate',
    sharePO: 'Share PO',
    qrCode: 'Generate QRCode',
    print: 'Print',
    settings: 'Settings',
    all_selected: 'select all',
    all_not_selected: 'deselect all',
    add_new_note: 'A new note has been added by $username.',
    review_notifications: 'Review Notifications',
    no_notifications: 'No notifications',
    mark_read: 'Mark Read',
    attach: 'Attachment',
    downloadAttachment: 'Download Attachment',
    noAttach: 'No attachments',
    shareBook: 'Share book',
    bookDistribute: 'Book distribute to',
    supplier: 'supplier',
    purchaser: 'purchaser',
    email: 'Email Address',
    continue: 'Continue',
    backHome: 'Back Home',
    failed: 'Sorry, something went wrong.',
    later: 'Later',
    search: 'Search',
  },
  footer: {
    terms: 'Terms',
    privacy: 'Privacy',
  },
  login: {
    signIn: 'Sign In',
    signOut: 'Sign Out',
    signUp: 'Sign Up',
    signUpEmail: 'SIGN UP WITH EMAIL',
  },
  toolbar: {
    switchDark: 'Switch to dark theme',
    switchLight: 'Switch to light theme',
    solutions: 'Solutions',
    enterprise: 'Enterprise',
    pricing: 'Pricing',
    profile: 'Profile',
    subscription: 'Subscription',
    security: 'Security',
    logout: 'Logout',
    download: 'Download',
  },
  countries: {
    cn: "China",
    us: "United States of America",
    other: "Other"
  },
  home: {
    pageTitle: "Request and Bid Quotes with Confidence",
    pageDesc: "Supply chain is point-to-point connected in trust. Sending RFQ/PO to designated person and getting the response instantly",
    sect1Title: "Sharing RFQ",
    sect1Desc: "You can customise the RFQ in pretty print and share with your colleagues such as accounting department.",
    sect2Title: "Drafting and Sending RFQ",
    sect2Desc: "You can draft an RFQ easily and send it right away",
    sect3Title: "Bidding RFQ",
    sect3Desc: "You can group the responders in REPLIED and UNREPLIED and mark your favoured bidder as Candidate. You can do the bidding analysis by sorting the unit price and/or lead time.",
    featureSectionTitle: "Features and Benefits",
    feature1Title: "Point-to-point",
    feature1Desc: "Point-to-point communication builds the trust.",
    feature2Title: "Bidding",
    feature2Desc: "Bidding makes your quotes in lower prices.",
    feature3Title: "Instant",
    feature3Desc: "You are notified when a new RFQ is coming. Your quick response increases your customer’s interest in you..",
    downloadTitle: "Instant",
    downloadDesc: "Your quick response increases your customer’s interest in you.",
  },
  solutions: {
    pageTitle: "Welcome to solutions.",
    sectionTitle1: "Establishing point-to-point connections in trust",
    sectionDesc1: "Establishing trust in supply chain connections is key to their success. The commitment between supplier and purchaser is crucial, and it is important to ensure fair pricing and accountable lead times. To build trust, utilise mobile identity authentication for signers, maintain updated purchase order transactions, engage in Request for Quotation (RFQ) bidding, enforce Minimum Order Quantity, and promptly respond to requests. By implementing these practices, a trustworthy and successful supply chain connection can be established. Open RFQ is available to assist in achieving these objectives.",
    sectionTitle2: "Digitizing Request for Quotation and Purchase Order",
    sectionDesc2: "The manual process of handling Request for Quotation (RFQ) and Purchase Order (PO) through emails, messages, attachments, and phone calls can result in important information being lost or miscommunicated. Digitising Request for Quotation and Purchase Order is a necessary step towards a more efficient and organised procurement process. By digitizing these documents, companies can improve the accuracy and reliability of their information, streamline their processes, and reduce the risk of data loss.",
  },
  enterprise: {
    pageTitle: "Welcome to solutions.",
    sectionTitle1: "Sharing accounts",
    sectionDesc1: "Introducing a solution that empowers enterprise users with effortless collaboration on company accounts without the hassle of upgrading service packages. The account owner has complete control with the ability to add or remove members at any moment. Say goodbye to the stress of managing account upgrades and hello to seamless teamwork.",
    sectionTitle2: "Sharing accesses",
    sectionDesc2: "Introducing the perfect solution for enterprise users! With our platform, you can effortlessly share access to Request for Quotation (RFQ) or Purchase Order (PO) with your team. Moreover, you can also conduct live bidding sessions for RFQ, making the procurement process smoother and more efficient than ever. Get ready to streamline your procurement process with ease!",
  },
  register: {
    emailPageTitle: "Send or receive Request For Quote / Purchaser Order at your fingertips",
    emailPageHint: "By signing up, you agree to our <0>User Terms</0> and <1>Privacy Policy</1>",
    emailHolder: "Work Email Address",
    continueWithGoogle: "Continue with Google",
    userTerms: '',
    codePageTitle: "We sent you a verification email to {{user}}. Please check your email to find the verification code and input it here.",
    codePageHint: "Don’t receive the code ?",
    resendCode: "Resend code",
    startWork: 'Sign up to start work',
    firstName: 'First Name*',
    firstNameMsg: 'Please input first name',
    lastName: 'Last Name*',
    lastNameMsg: 'Please input last name',
    Email: 'Email*',
    emailMsg1: 'Please input an email',
    emailMsg2: 'Please input an valid email',
    emailError: 'User email is already existed',
    newPsd: 'Password*',
    infoMsg1: 'Strong passwords or passphrases are important for security. Your password must use:',
    infoMsg2: 'Minimum 8 characters',
    infoMsg3: '1 Letter',
    infoMsg4: '1 Number',
    passMsg1: 'Please input a password',
    passMsg2: 'Password is not qualified',
    rePsd: 'Re-Password*',
    repsdMsg: 'Please confirm your password',
    errorMsg3: 'Sorry, the two passwords that you entered do not match!',
    company: 'Company',
    createAccount: 'Create account',
    userExist: 'This user is already existed',
    unknownError: 'Network error',
    pendingTitle: 'Welcome!',
    pendingContent: 'Your account is pending for approval, please wait for notification sent to your email.',
    successContent1: 'We are so happy to have you on board.',
    successContent2: 'You can start using by login.',
    successContent3: 'Back to login',
    termsPrivacy: 'By creating an account, you agree to the Terms of Service. For more information about openuse.io\'s privacy practices, see the openuse.io Privacy Statement. We\'ll occasionally send you account-related emails.',
    gotoSign: 'Will automatically go to Sign In page',
  },
  security: {
    oldPassword: 'Old Password*',
    newPassword: 'New Password*',
    rePassword: 'Re-Password*',
    change: 'Change',
    changePassword: 'Change Password',
    passwordChangedSuccess: 'The password has been changed successfully.',
    cannotChangePassword: 'Can not change password.',
  },
  profile: {
    title: 'My Profile',
    email: 'Sign-up Email',
    associatedEmail: 'Associated Email',
    phone: 'Phone',
    street: 'Street',
    city: 'City',
    state: 'State',
    zip: 'Zip',
    country: 'Country',
    companyMsg: 'Please input company name',
    phoneMsg: 'Please input phone number',
    uploadWarning1: 'Please upload the (JPG/PNG) picture',
    uploadWarning2: 'Please upload pictures within 1M',
    uploadError: 'Uploading picture failed',
    addAvatar: 'Add Avatar',
    addLogo: 'Add Logo',
    saveProfileSuccess: 'Your profile has been successfully saved',
    saveProfileFailed: 'Can not save your profile. Please try it again later.',
  },
  pricing: {
    contactSales: 'Contact sales',
    contactSalesDesc: 'Please contact sales',
    gotIt: 'Got it',
  },
  subscription: {
    title: 'Subscription',
    upgrade: 'Upgrade',
    cancelTitle: 'Cancel Subscription',
    plan: 'Plan',
    inactive: 'cancelled',
    nextPayment: 'Next payment',
    trial: 'Trial',
    basic: 'Basic',
    professional: 'Professional',
    enterprise: 'Enterprise',
    expiration: 'Expiration',
    cancelSubscriptionDesc: 'This will delete all the data including sent and received RFQ, sent and received PO, archived PO, your vendor contacts, and so on.',
    unsubscribeDialogTitle: 'Unsubscribe confirm',
    unsubscribeDialogDesc: 'We will cancel the service through your credit card with a monthly service mantissa of {card_last_4}. The service fee paid in the current month will not be refunded, and the cancellation will take effect immediately.',
    cancelSubscriptionSuccessfulDesc: 'Your subscription has been successfully canceled. You can continue using the current plan till {last_date}.',
    invoices: 'Invoices',
    view: 'View Details',
    failed: 'Server is busy now, Please try again later',
    paymentTime: 'Payment time',
    invoiceNumber: 'Invoice number',
    amountDue: 'Amount due',
    startDate: 'Start date',
    endDate: 'End date',
  }
}
export default langEN;
