import {Route, Switch} from "react-router-dom";
import useStyles from './styles/global';
import React from "react";
import Home from "./pages/home/Home";
import Solutions from "./pages/solutions/Solutions";
import Enterprise from "./pages/enterprise/Enterprise";
import Pricing from "./pages/pricing/Pricing";
import TermsPage from "./pages/terms/termsPrivacy";
import RegisterPage from "./pages/register/RegisterPage";
import RegisterSuccess from "./pages/register/RegisterSuccessPage";
import Security from "./pages/security/Security";
import Profile from "./pages/profile/Profile";
import Subscription from "./pages/subscription/Subscription";
import CancelSubscription from "./pages/subscription/CancelSubscription";
import CancelSubscriptionSuccessful from "./pages/subscription/CancelSubscriptionSuccessful";
import Invoices from "./pages/subscription/Invoices";

export default function AppRouters(props) {
  useStyles();
  return (
    <Switch>
      <Route path='/register'>
        <RegisterPage />
      </Route>
      <Route path="/register-success" component={RegisterSuccess}/>
      <Route path='/solutions' exact>
        <Solutions/>
      </Route>
      <Route path='/enterprise' exact>
        <Enterprise/>
      </Route>
        <Route path='/pricing' exact>
            <Pricing/>
        </Route>
      <Route path='/profile' exact>
        <Profile/>
      </Route>
      <Route path='/security' exact>
        <Security/>
      </Route>
      <Route path='/subscription' exact>
        <Subscription/>
      </Route>
      <Route path='/subscription/cancel' exact>
        <CancelSubscription/>
      </Route>
      <Route path='/subscription/cancel/successful' exact>
        <CancelSubscriptionSuccessful />
      </Route>
      <Route path='/subscription/invoice' exact>
        <Invoices />
      </Route>
      <Route path='/terms-privacy'>
        <TermsPage/>
      </Route>
      <Route path='/'>
        <Home/>
      </Route>
    </Switch>
  )

}
