import React from 'react';
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import {useTranslation} from "react-i18next";
import appleStore from "../assets/img/apple_store.png";
import googlePlay from "../assets/img/google_play.png";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    color: theme.currentTheme.colors[0],
    fontFamily: 'RobotoRegular',
    padding: '30px',
    [theme.breakpoints.down('xs')]: {
      padding: '10px',
    },
  },
  siteWrapper: {
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  termsWrapper: {
    justifyContent: 'right',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'center',
    },
  },
  terms: {
    textAlign: 'right',
    cursor: 'pointer',
    [theme.breakpoints.down('xs')]: {
      textAlign: 'left',
    },
  },
  btnTxt: {
    fontSize: '1rem',
    textDecoration: 'none',
    color: theme.currentTheme.colors[0],
    '&:hover': {
      color: theme.currentTheme.colors[4],
    },
    '@media (max-width:600px)': {
      fontSize: '0.8rem',
    }
  },
  root: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '40px 20px 40px 20px',
    '@media (max-width:600px)': {
      flexDirection: 'column',
      alignItems: 'center',
    }
  },
  right: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '@media (max-width:600px)': {
      marginTop: '20px',
    }
  }

}));

export default function Footer() {
  const classes = useStyles();
  const {t} = useTranslation();

  const jump2TermsPage = () => {
    const url = '/terms-privacy?type=terms';
    window.open(url, '_blank');
  };

  const jump2PrivacyPage = () => {
    const url = '/terms-privacy?type=privacy';
    window.open(url, '_blank');
  };

  return (
    <div className={classes.root}>
      <div id={'app_download'}>
        <img src={appleStore} alt='' style={{width: '104px', height: '36px', cursor: 'pointer'}}
             onClick={() => {window.open('https://apps.apple.com/us/app/open-rfq/id1641606984')}}/>
        &nbsp;&nbsp;
        <img src={googlePlay} alt='' style={{width: '104px', height: '36px', cursor: 'pointer'}}
             onClick={() => {window.open('https://play.google.com/store/apps/details?id=net.opendais.rfq')}} />
      </div>
      <div className={classes.right}>
        <Typography variant="body1">openuse.io © 2023</Typography>
        <a style={{marginLeft: '50px'}} onClick={jump2TermsPage} className={classes.btnTxt}>
          {t('footer.terms')}
        </a>
        <a style={{marginLeft: '10px'}} onClick={jump2PrivacyPage} className={classes.btnTxt}>
          {t('footer.privacy')}
        </a>
      </div>

    </div>
  )
}
