const commonTheme = {
  typography: {
    fontFamily: [
      'RobotoRegular',
      'Roboto',
      'RobotoBold',
      'IBMPlexSans-Regular',
      'Helvetica',
      'Arial',
      'sans-serif'
    ].join(','),
    h1: {
      fontSize: '3rem',
      fontFamily: 'RobotoBold'
    },
    h2: {
      fontSize: '2.25rem',
      fontFamily: 'RobotoBold',
      '@media (max-width:600px)': {
        fontSize: '1.5rem',
      },
    },
    h3: {
      fontSize: '1.125rem',
      fontFamily: 'RobotoBold'
    },
    h4: {
      fontSize: '0.875rem',
      fontFamily: 'RobotoBold'
    },
    body1: {
      fontSize: '1rem',
      fontFamily: 'RobotoRegular',
      '@media (max-width:600px)': {
        fontSize: '0.8rem',
      },
    },
    body2: {
      fontSize: '0.875rem',
      fontFamily: 'RobotoRegular',
      '@media (max-width:600px)': {
        fontSize: '0.7rem',
      },
    },
    subtitle1: {
      fontSize: '1.125rem',
      fontFamily: 'RobotoRegular',
      '@media (max-width:600px)': {
        fontSize: '1rem',
      },
    },
    subtitle2: {
      fontSize: '1rem',
      fontFamily: 'RobotoRegular',
      '@media (max-width:600px)': {
        fontSize: '0.8rem',
      },
    }
  },
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      root: {
        borderRadius: '4px',
        textTransform: 'none',
        height: '40px',
      },
      contained: {
        text: {
          color: '#FFFFFF'
        },
        backgroundColor: '#FFFFFF',
        '&:hover': { // changes colors for hover state
          backgroundColor: '#FFFFFF',
        },
      },
      text: {
        color: '#12498E',
        fontWeight: 'bold',
        '&:hover': { // changes colors for hover state
          color: '#3290ff',
          backgroundColor: 'transparent',
        },
      },
      containedPrimary: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        backgroundColor: '#114281',
        '&:hover': { // changes colors for hover state
          backgroundColor: '#3290ff',
        },
      },
      containedSecondary: {
        color: '#FFFFFF',
        fontWeight: 'bold',
        backgroundColor: '#2DA44E',
        '&:hover': { // changes colors for hover state
          backgroundColor: 'rgb(44,151,75)',
        },
      }
    },
    MuiFilledInput: {
      root: {
        // backgroundColor: '#ffffff',
        '&:hover': {
          // backgroundColor: '#ffffff',
        },
        '.Mui-focused': {
          // backgroundColor: '#ffffff',
        },
      },
      underline: {
        '&:before': {
          border: 'none !important',
        },
        '&:after': {
          border: 'none !important',
        }
      }
    },
    MuiFormLabel: {
      root: {
        // color: "#000000",
        '& .MuiForm-Focused': {
          // color: 'rgba(0, 0, 0, 0.87)'
        }
      }
    },
    MuiOutlinedInput: {
      root: {
        fontSize: '14px'
      }
    },
    MuiMenuItem: {
      root: {
        fontSize: '14px'
      }
    }
    // MuiDialog: {
    //     paper: {
    //         border: 'solid 4px #656565'
    //     }
    // },
  },
}

export default commonTheme;
