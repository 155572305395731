import './App.css';
import {lightTheme, darkTheme} from './theme';
import {ThemeProvider} from '@material-ui/core/styles';
import {createTheme} from '@material-ui/core/styles';
import useToken from './hooks/useToken';
import React from "react";
import {BrowserRouter as Router} from "react-router-dom";
import AppRouters from "./AppRouters";
import IAMCallback from "./components/IAMCallback";
import RedirectTo from "./components/RedirectTo";
import {getLoginURL} from './services/axiosHelper';
import {useDarkMode} from "./hooks/useDarkMode";

function App() {
  const {token} = useToken();
  const [theme, componentMounted] = useDarkMode();

  if (!componentMounted) {
    return <div/>
  }

  const path = window.location.pathname;
  const baseURL = process.env.REACT_APP_BASE_URL;

  if (path === baseURL + '/iamCallback') {
    return (
      <IAMCallback/>
    )
  }

  const publicRouterPool = new Set([
    baseURL + '/',
    baseURL + '/register',
    baseURL + '/solutions',
    baseURL + '/enterprise',
    baseURL + '/terms-privacy',
  ]);

  if (!publicRouterPool.has(path) && !token) {

    console.log('RedirectTo iam url');

    window.localStorage.setItem('access_url', window.location.href);
    const iamLoginURL = getLoginURL();
    return (
      <RedirectTo url={iamLoginURL}/>
    )
  } else {
    return (
      <ThemeProvider theme={theme === 'light' ? createTheme(lightTheme) : createTheme(darkTheme)}>
        <Router basename={process.env.REACT_APP_BASE_URL}>
          <AppRouters></AppRouters>
        </Router>
      </ThemeProvider>
    );
  }

}

export default App;
