import {axiosCore} from "./axiosHelper";

const getProfile = (token) => {

  const url = '/rfq/auth/user/profile';

  return new Promise((resolve, reject) => {
    axiosCore.request({
      url: url,
      method: 'get',
      headers: {
        'content-type': 'application/json',
        'accept': '*/*',
        'Authorization': 'opendais ' + token
      },
    }).then(function (result) {
      resolve(result);
    }).catch(function (e) {
      console.error('getProfile: ', e);
      reject(e);
    });
  });

}

const getImage = (token, id) => {

  const url = '/rfq/auth/avatar/' + id;

  return new Promise((resolve, reject) => {
    axiosCore.request({
      url: url,
      method: 'get',
      headers: {
        'content-type': 'application/json',
        'accept': '*/*',
        'Authorization': 'opendais ' + token
      },
      responseType: 'blob',
    }).then(function (result) {
      resolve(result);
    }).catch(function (e) {
      console.error('getImage: ', e);
      reject(e);
    });
  });

}

const uploadAvatar = (url, token, file) => {
  return new Promise((resolve, reject) => {
    axiosCore.request({
      url: url,
      method: 'put',
      headers: {
        'content-type': 'application/json',
        'accept': '*/*',
        'Authorization': 'opendais ' + token
      },
      data: {
        'avatar': Object.values(file),
      },
      responseType: 'blob',
    }).then(function (result) {
      resolve(result);
    }).catch(function (e) {
      console.error('uploadAvatar: ', e);
      reject(e);
    });
  });
}

const updateProfile = (token, data) => {

  const url = '/rfq/auth/user/profile';

  return new Promise((resolve, reject) => {
    axiosCore.request({
      url: url,
      method: 'put',
      headers: {
        'content-type': 'application/json',
        'accept': '*/*',
        'Authorization': 'opendais ' + token
      },
      data: data,
    }).then(function (result) {
      resolve(result);
    }).catch(function (e) {
      console.error('updateProfile: ', e);
      reject(e);
    });
  });
}

const checkSignUpEmail = (email) => {

  const url = '/rfq/public/users/' + email + '/check';

  return new Promise((resolve, reject) => {
    axiosCore.request({
      url: url,
      method: 'get',
      headers: {
        'content-type': 'application/json',
        'accept': '*/*',
      },
    }).then(function (result) {
      resolve(result);
    }).catch(function (e) {
      console.error('checkSignUpEmail: ', e);
      reject(e);
    });
  });

}


export default {
  getProfile,
  getImage,
  uploadAvatar,
  updateProfile,
  checkSignUpEmail,
}
