import {useEffect, useState} from "react"
import jwtUtil from '../utils/jwtUtil';
import iamService from '../services/iamService';
import useToken from '../hooks/useToken';

export default function IAMCallback() {
  const _hash = window.location.hash;
  const [hash] = useState(_hash);

  const {setToken} = useToken();
  useEffect(() => {
    const accessToken = jwtUtil.encode(hash);
    console.log(accessToken);
    iamService.checkToken(accessToken).then((res) => {
      setToken(res.data);
      if (window.localStorage.getItem('access_url')) {
        const redirectUrl = window.localStorage.getItem('access_url');
        window.localStorage.removeItem('access_url');
        window.location.href = redirectUrl;
      } else {
        window.location.href = "/";
      }

    }).catch((error) => {
      console.error(error);
    })
  });

  return (
    <>
    </>
  )
}
