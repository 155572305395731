import React, { useState } from 'react';
import { Upload } from 'antd';
import ImgCrop from 'antd-img-crop';
import rfqService from "../../services/rfqService";
import useToken from "../../hooks/useToken";
import {Avatar, Snackbar} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  alertTitle: {
    fontFamily: 'RobotoRegular',
    fontSize: '16px',
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
    color: '#127bb0',
  },
}));

export default function UploadAvatar(props) {

  const { t } = useTranslation();
  const {token} = useToken();
  const classes = useStyles();

  const [openWarning1, setOpenWarning1] = useState(false);
  const [openWarning2, setOpenWarning2] = useState(false);
  const [openError, setOpenError] = useState(false);

  const beforeUpload = file => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      setOpenWarning1(true);
      return Upload.LIST_IGNORE;
    }

    console.log('file size: ' + file.size / 1024 / 1024 + 'MB');

    const isLt2M = file.size / 1024 / 1024 < 1;
    if (!isLt2M) {
      setOpenWarning2(true);
      return Upload.LIST_IGNORE;
    }

    return isJpgOrPng && isLt2M;
  };

  const uploadProps = {
    showUploadList: false,
    beforeUpload,
    customRequest: async ({ file }) => {
      try {

        props.setUploadAvatar(true);

        const reader4Upload = new FileReader();
        reader4Upload.readAsArrayBuffer(file);
        reader4Upload.onloadend = async (e) => {
          let unit8Data = new Uint8Array(reader4Upload.result);
          console.log('unit8_data: ', unit8Data);
          let type = Object.prototype.toString.call(unit8Data);
          console.log('type: ', type);
          rfqService.uploadAvatar(props.uploadUrl, token.access_token, unit8Data).then(remoteResult => {
            if (remoteResult.status === 200) {
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onloadend = async (e) => {
                props.setAvatar(reader.result);
                props.setUploadAvatar(false);
              }
            } else {
              setOpenError(true);
              props.setUploadAvatar(false);
            }
          }).catch((error) => {
            console.log(error);
            setOpenError(true);
            props.setUploadAvatar(false);
          });

        }

      } catch (err) {
        console.log(err);
        setOpenError(true);
        props.setUploadAvatar(false);
      }
    }

  };

  const handleErrorClose = () => {
    setOpenError(false);
  }

  const handleWarningClose1 = () => {
    setOpenWarning1(false);
  }

  const handleWarningClose2 = () => {
    setOpenWarning2(false);
  }

  return (
    <div style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
      <Avatar className={props.className} style={props.style} src={props.avatar} />

      <div style={{position: 'relative'}}>
        <ImgCrop rotate grid>
          <Upload {...uploadProps} disabled={props.uploadAvatar}>
            {props.children}
            {
              props.uploadAvatar &&
                <CircularProgress size={24} className={classes.buttonProgress} />
            }
          </Upload>
        </ImgCrop>
      </div>

      <Snackbar open={openWarning1} autoHideDuration={6000} onClose={handleWarningClose1}>
        <Alert onClose={handleWarningClose1} severity="warning">
          <span className={classes.alertTitle}>{t('profile.uploadWarning1')}</span>
        </Alert>
      </Snackbar>

      <Snackbar open={openWarning2} autoHideDuration={6000} onClose={handleWarningClose2}>
        <Alert onClose={handleWarningClose2} severity="warning">
          <span className={classes.alertTitle}>{t('profile.uploadWarning2')}</span>
        </Alert>
      </Snackbar>

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error">
          <span className={classes.alertTitle}>{t('profile.uploadError')}</span>
        </Alert>
      </Snackbar>

    </div>
  );
};
