import {useTranslation} from "react-i18next";
import Toolbar from "@material-ui/core/Toolbar";
import OpenDaisLogo from "./OpenDaisLogo";
import {Button} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import {makeStyles} from "@material-ui/core/styles";
import iamService from "../services/iamService";
import ThemeToggle from "./ThemeToggle";
import {useHistory} from "react-router-dom";
import {useDarkMode} from "../hooks/useDarkMode";

const useStyles = makeStyles((theme) => ({
    header: {
        backgroundColor: theme.currentTheme.backgrounds[0],
        height: '58px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'space-between',
    },
    iconButton: {
        color: '#FFFFFF'
    },
    signBtn: {
        borderRadius: '28px',
        [theme.breakpoints.up('md')]: {
            width: '100px',
        },
    },
    signBtnTxt: {
        fontSize: '16px',
        fontFamily: 'RobotoRegular',
        letterSpacing: '0.08px',
        fontWeight: '600',
    }
}));

export default function SimplifiedHeader(props) {
    const classes = useStyles();
    const history = useHistory();
    const {t} = useTranslation();
    const signInMode = props.mode && props.mode === 'SIGN_UP';
    const [theme] = useDarkMode();

    const jump2Home = () => {
        const homeURL = '/';
        history.push(homeURL);
    }

    const signInHandler = () => {
        iamService.redirectToLogin();
    }

    const signUpHandler = () => {
        window.location.href = '/register';
    }

    return (
        <Toolbar className={classes.header}>
            <OpenDaisLogo logoClick={jump2Home} darkMode={theme === 'dark'}></OpenDaisLogo>

            <Box sx={{ flexGrow: 1 }} />

            <ThemeToggle style={{marginRight: '10px'}}/>

            {
                signInMode &&
                <Button variant={'contained'} color="primary" className={classes.signBtn} onClick={signInHandler}>
                    <span className={classes.signBtnTxt}>{t('login.signIn')}</span>
                </Button>
            }
            {
                !signInMode &&
                <Button variant={'contained'} color="primary" className={classes.signBtn} onClick={signUpHandler}>
                    <span className={classes.signBtnTxt}>{t('login.signUp')}</span>
                </Button>
            }
        </Toolbar>
    );
}
