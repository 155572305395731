import logoDark from '../assets/img/newLogoDark.svg';
import logoLight from '../assets/img/newLogoLight.svg';
import {makeStyles} from '@material-ui/core/styles';
import {useEffect, useState} from "react";

const useStyles = makeStyles(() => ({
  header: {
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  logo: {
    width: '40px',
    paddingLeft: '10px',
    cursor: 'pointer',
  }

}));
export default function OpenDaisLogo(props) {
  const classes = useStyles();
  const clickLogoHandler = () => {
    if (props.logoClick) {
      props.logoClick();
    }
  }

  const [currentLogo, setCurrentLogo] = useState(logoDark);

  useEffect(() => {
    if (props.darkMode === true) {
      setCurrentLogo(logoDark);
    } else {
      setCurrentLogo(logoLight);
    }
  }, [props.darkMode])

  return (
    <div className={classes.header}>
      <img className={classes.logo} src={currentLogo} onClick={clickLogoHandler} alt='openuse.io' />
    </div>
  )
}
