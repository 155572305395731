import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import CommonLayout from "../../components/CommonLayout";
import React, {useEffect, useState} from "react";
import rfqService from "../../services/rfqService";
import useToken from "../../hooks/useToken";
import {Backdrop, Button, CircularProgress, Snackbar, TextField} from "@material-ui/core";
import FormFunction from "./FormFunction";
import LoadingButton from "../../components/LoadingButton";
import person from "../../assets/img/person.png";
import star from "../../assets/img/star.png";
import UploadAvatar from "../../components/UploadAvatar";
import {Alert, Skeleton} from "@material-ui/lab";
import {Result} from "antd";
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    paddingBottom: '80px',
    paddingTop: '20px',
    backgroundColor: theme.currentTheme.background,
    [theme.breakpoints.down('sm')]: {
      padding: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 20px 50px 20px',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title1: {
    fontSize: '36px',
    fontFamily: 'RobotoRegular',
    '@media (max-width:600px)': {
      fontSize: '22px',
    },
    fontWeight: '300',
    textAlign: 'center',
    marginBottom: '20px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#127bb0',
  },
  submitButton: {
    width: '100%',
    height: '48px !important',
    marginTop: '30px',
    fontFamily: 'RobotoRegular',
    fontSize: '16px',
  },
  alertTitle: {
    fontFamily: 'RobotoRegular',
    fontSize: '16px',
  },
  dialogText: {
    fontWeight: '600',
    color: theme.currentTheme.colors[8],
  },
  resultText: {
    color: theme.currentTheme.colors[0],
  },
  content: {
    width: '450px',
    textAlign: 'end',
    '@media (max-width:600px)':  {
      width: '95%',
    },
  },
  avatar: {
    width: '100px',
    height: '100px',
    '@media (max-width:600px)':  {
      width: '70px',
      height: '70px',
    },
  }
}));

export default function Profile() {

  const {t} = useTranslation();
  const {token} = useToken();
  const classes = useStyles();
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(false);
  const [openSuccess, setOpenSuccess] = useState(false);
  const [openError, setOpenError] = useState(false);

  const {fields, isFormValid, handleChange} = FormFunction();
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [userAvatar, setUerAvatar] = useState(person);
  const [companyAvatar, setCompanyAvatar] = useState(star);
  const [serverError, setServerError] = useState(false);
  const [uploadAvatar, setUploadAvatar] = useState(false);
  const [uploadLogo, setUploadLogo] = useState(false);

  useEffect(() => {

    setOpenBackdrop(true);

    rfqService.getProfile(token['access_token']).then((result) => {
      console.log('getProfile result: ', result);

      if (result.status === 200) {
        const userProfile = result.data.data;

        fields.firstName.value = userProfile['firstName'];
        fields.lastName.value = userProfile['lastName'];
        fields.email.value = userProfile['userEmail'];
        fields.associatedEmail.value = userProfile['associatedEmail'];
        fields.companyName.value = userProfile['companyName'];
        if (userProfile['companyPhone'] && userProfile['companyPhone'].length > 0) {
          fields.phone.value = userProfile['companyPhone'][0];
        }
        fields.street.value = userProfile['companyStreet'];
        fields.city.value = userProfile['companyCity'];
        fields.state.value = userProfile['companyState'];
        fields.zip.value = userProfile['companyZip'];
        fields.country.value = userProfile['companyCountry'];
        fields.companyId.value = userProfile['companyId'];

        const userAvatar = userProfile['userAvatar'];
        if (userAvatar) {
          rfqService.getImage(token['access_token'], userAvatar).then((img) => {
            setUerAvatar(URL.createObjectURL(img.data));
          });
        }

        const companyAvatar = userProfile['companyAvatar'];
        if (companyAvatar) {
          rfqService.getImage(token['access_token'], companyAvatar).then((img) => {
            setCompanyAvatar(URL.createObjectURL(img.data));
          });
        }
      } else {
        setOpenError(true);
        setServerError(true);
      }

      setOpenBackdrop(false);

    }).catch((_) => {
      setOpenBackdrop(false);
      setOpenError(true);
      setServerError(true);
    });

  }, []);

  const saveProfile = () => {
    setIsLoading(true);

    if (!isFormValid()) {
      setIsLoading(false);
      return;
    }

    const data = {
      firstName: fields.firstName.value,
      lastName: fields.lastName.value,
      companyName: fields.companyName.value,
      companyPhone: [fields.phone.value],
      companyStreet: fields.street.value,
      companyCity: fields.city.value,
      companyState: fields.state.value,
      companyZip: fields.zip.value,
      companyCountry: fields.country.value,
    };

    rfqService.updateProfile(token['access_token'], data).then((result) => {

      if (result.status === 200) {
        setOpenSuccess(true);
      } else {
        setOpenError(true);
      }

      setIsLoading(false);
    }).catch((_) => {
      setOpenError(true);
      setIsLoading(false);
    });
  }

  const handleSuccessClose = () => {
    setOpenSuccess(false);
  }

  const handleErrorClose = () => {
    setOpenError(false);
  }

  return (

    <CommonLayout>
      <div className={classes.pageContainer}>

        <div className={classes.title1}>
          {t('profile.title')}
        </div>

        {
          !serverError && !openBackdrop &&
          <div className={classes.content}>

            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-evenly', marginTop: '20px'}}>

              <UploadAvatar
                uploadUrl={'/rfq/auth/user/avatar'}
                avatar={userAvatar}
                setAvatar={setUerAvatar}
                uploadAvatar={uploadAvatar}
                setUploadAvatar={setUploadAvatar}
                className={classes.avatar}
              >

                <Button style={{fontFamily: 'RobotoRegular'}} disabled={uploadAvatar}> {t('profile.addAvatar')}</Button>

              </UploadAvatar>

              <UploadAvatar
                uploadUrl={'/rfq/auth/companies/' + fields.companyId.value + '/avatar'}
                avatar={companyAvatar}
                setAvatar={setCompanyAvatar}
                uploadAvatar={uploadLogo}
                setUploadAvatar={setUploadLogo}
                className={classes.avatar}
              >

                <Button style={{fontFamily: 'RobotoRegular'}} disabled={uploadLogo}> {t('profile.addLogo')}</Button>

              </UploadAvatar>

            </div>
            <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '20px'}}>
              <TextField
                style={{width: '49%'}}
                label={fields.firstName.title}
                variant='outlined'
                autoComplete='none'
                value={fields.firstName.value}
                onChange={(e) => {
                  handleChange(fields.firstName.name, e.target.value)
                }}
                error={!fields.firstName.valid}
                helperText={fields.firstName.errorMsg}
              />
              <TextField
                style={{width: '49%'}}

                label={fields.lastName.title}
                variant='outlined'
                autoComplete='none'
                value={fields.lastName.value}
                onChange={(e) => {
                  handleChange(fields.lastName.name, e.target.value)
                }}
                error={!fields.lastName.valid}
                helperText={fields.lastName.errorMsg}/>
            </div>

            <TextField
              fullWidth
              style={{marginTop: '20px'}}
              label={fields.email.title}
              variant='outlined'
              autoComplete='none'
              value={fields.email.value}
              disabled={true}
            />

            <TextField
              fullWidth
              style={{marginTop: '20px'}}
              label={fields.associatedEmail.title}
              variant='outlined'
              autoComplete='none'
              value={fields.associatedEmail.value}
              disabled={true}
            />

            <TextField
              fullWidth
              style={{marginTop: '20px'}}
              label={fields.companyName.title}
              variant='outlined'
              autoComplete='none'
              value={fields.companyName.value}
              onChange={(e) => {
                handleChange(fields.companyName.name, e.target.value)
              }}
              error={!fields.companyName.valid}
              helperText={fields.companyName.errorMsg}
            />

            <TextField
              fullWidth
              style={{marginTop: '20px'}}
              label={fields.phone.title}
              variant='outlined'
              autoComplete='none'
              value={fields.phone.value}
              onChange={(e) => {
                handleChange(fields.phone.name, e.target.value)
              }}
              error={!fields.phone.valid}
              helperText={fields.phone.errorMsg}
            />

            <TextField
              fullWidth
              style={{marginTop: '20px'}}
              label={fields.street.title}
              variant='outlined'
              autoComplete='none'
              value={fields.street.value}
              onChange={(e) => {
                handleChange(fields.street.name, e.target.value)
              }}
              error={!fields.street.valid}
              helperText={fields.street.errorMsg}
            />

            <TextField
              fullWidth
              style={{marginTop: '20px'}}
              label={fields.city.title}
              variant='outlined'
              autoComplete='none'
              value={fields.city.value}
              onChange={(e) => {
                handleChange(fields.city.name, e.target.value)
              }}
              error={!fields.city.valid}
              helperText={fields.city.errorMsg}
            />

            <TextField
              fullWidth
              style={{marginTop: '20px'}}
              label={fields.state.title}
              variant='outlined'
              autoComplete='none'
              value={fields.state.value}
              onChange={(e) => {
                handleChange(fields.state.name, e.target.value)
              }}
              error={!fields.state.valid}
              helperText={fields.state.errorMsg}
            />

            <TextField
              fullWidth
              style={{marginTop: '20px'}}
              label={fields.zip.title}
              variant='outlined'
              autoComplete='none'
              value={fields.zip.value}
              onChange={(e) => {
                handleChange(fields.zip.name, e.target.value)
              }}
              error={!fields.zip.valid}
              helperText={fields.zip.errorMsg}
            />

            <TextField
              fullWidth
              style={{marginTop: '20px'}}
              label={fields.country.title}
              variant='outlined'
              autoComplete='none'
              value={fields.country.value}
              onChange={(e) => {
                handleChange(fields.country.name, e.target.value)
              }}
              error={!fields.country.valid}
              helperText={fields.country.errorMsg}
            />

            <LoadingButton className={classes.submitButton} isLoading={isLoading} onClick={saveProfile}>
              {t('common.save')}
            </LoadingButton>

          </div>
        }

        {
          openBackdrop &&
          <div className={classes.content} style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-end'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', width: '100%'}}>
              <Skeleton variant="circle" style={{width: '100px', height: '100px'}} />
              <div style={{flexGrow: 1, marginLeft: '20px'}}>
                <Skeleton animation="wave" height={26} width="62%"  />
                <Skeleton animation="wave" height={26} width="100%" style={{marginTop: 20}} />
                <Skeleton animation="wave" height={26} width="100%" style={{marginTop: 20}} />
              </div>
            </div>
            <Skeleton animation="wave" height={26} width="95%" style={{marginTop: 20}} />
            <Skeleton animation="wave" height={26} width="95%" style={{marginTop: 20}} />
            <Skeleton animation="wave" height={26} width="95%" style={{marginTop: 20}} />
          </div>
        }

        <Backdrop className={classes.backdrop} open={openBackdrop}>
          <CircularProgress color="inherit"/>
        </Backdrop>

      </div>

      {
        serverError &&
        <Result
          style={{marginTop: '-100px'}}
          status="500"
          title={<span className={classes.resultText}>500</span>}
          subTitle={<span className={classes.resultText}>{t('common.failed')}</span>}
          extra={<Button type="primary" variant="contained" color='primary' onClick={() => history.push('/')}>{t('common.backHome')}</Button>}
        />
      }

      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleSuccessClose}>
        <Alert onClose={handleSuccessClose} severity="success">
          <span className={classes.alertTitle}>{t('profile.saveProfileSuccess')}</span>
        </Alert>
      </Snackbar>

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error">
          <span className={classes.alertTitle}>{t('common.failed')}</span>
        </Alert>
      </Snackbar>

    </CommonLayout>

  );

}
