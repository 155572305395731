import React, {useState} from "react";
import {useTranslation} from 'react-i18next';

export default function FormFunction() {
  const { t } = useTranslation();
  const [fields, setFields] = useState({
    firstName: {
      title: t('register.firstName'),
      name: 'firstName',
      value: '',
      valid: true,
      rules: {
        required: true,
        requiredMessage: t('register.firstNameMsg'),
      }
    },
    lastName: {
      title: t('register.lastName'),
      name: 'lastName',
      value: '',
      valid: true,
      rules: {
        required: true,
        requiredMessage: t('register.lastNameMsg'),
      }
    },
    email: {
      title: t('profile.email'),
      name: 'email',
      value: '',
      valid: true,
    },
    associatedEmail: {
      title: t('profile.associatedEmail'),
      name: 'associatedEmail',
      value: '',
      valid: true,
    },
    companyName: {
      title: t('register.company'),
      name: 'companyName',
      value: '',
      valid: true,
    },
    phone: {
      title: t('profile.phone'),
      name: 'phone',
      value: '',
      valid: true,
    },
    street: {
      title: t('profile.street'),
      name: 'street',
      value: '',
      valid: true,
    },
    city: {
      title: t('profile.city'),
      name: 'city',
      value: '',
      valid: true,
    },
    state: {
      title: t('profile.state'),
      name: 'state',
      value: '',
      valid: true,
    },
    zip: {
      title: t('profile.zip'),
      name: 'zip',
      value: '',
      valid: true,
    },
    country: {
      title: t('profile.country'),
      name: 'country',
      value: '',
      valid: true,
    },
    companyId: {
      value: ''
    }

  });

  const handleChange = function (fieldName, newValue) {
    const data = {...fields}
    const field = data[fieldName];
    field.value = newValue;
    field.valid = checkValid(field, newValue);
    setFields(data)
  }

  const isFormValid = function () {
    let formValid = true
    const data = {...fields}
    Object.keys(data).forEach((key) => {
      formValid = checkValid(data[key], data[key].value) && formValid;
    })
    setFields(data);
    return formValid;
  }

  const checkValid = function (field, newValue) {
    let valid = checkRequired(field, newValue);


    return valid;
  }

  const checkRequired = function (field, newValue) {
    let isRequired = field.rules && field.rules.required;
    let valid = true;
    if (isRequired) {
      if (typeof newValue === "string") {
        valid = !!newValue && !!newValue.trim();
      } else if (typeof newValue === "undefined") {
        valid = false;
      } else if (typeof newValue === "object") {
        valid = !!newValue;
      }
    }
    if (!valid) {
      field.errorMsg = field.rules.requiredMessage;
    } else {
      field.errorMsg = '';
    }
    field.valid = valid;
    return valid;
  }

  return {
    fields: fields,
    setFields: setFields,
    isFormValid: isFormValid,
    handleChange: handleChange,
  }
}
