import {useEffect, useState} from "react";
import useToken from "../../hooks/useToken";
import userService from "../../services/userService";
import LocalEncrypt from "../../utils/encrypt";
import {Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import CommonLayout from "../../components/CommonLayout";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({

  dialogText: {
    fontWeight: '600',
    color: theme.currentTheme.colors[8],
  },

}));

export default function Pricing() {
  const {token} = useToken();
  const { t } = useTranslation();
  const history = useHistory();
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (token && token['access_token']) {
      userService.getPaymentStatus(token['access_token']).then((data) => {

        console.log('payment type: ', data.data.paymentType);

        if ((data.data.paymentType === 'Professional' || data.data.paymentType === 'Enterprise') && data.data.active) {
          setOpen(true);
        } else {
          jumpToPayment(token['access_token'], data.data);
        }

      })
    }
  }, []);

  const handleClose = () => {
    setOpen(false);
    history.goBack();
  };

  const jumpToPayment = (token, data) => {
    const dataWithLang = {
      lang: 'en',
      paymentType: data.paymentType && data.active ? data.paymentType : 'free',
      cardLast4: data.cardLast4 ? data.cardLast4 : '',
    };
    let url = process.env.REACT_APP_PAYMENT_URL + '/rfq-payment/home?';
    url += 'token=' + token + '&params=' + LocalEncrypt(JSON.stringify(dataWithLang));
    // window.location.replace(url);
    window.location.href = url;
  }

  return (
    <CommonLayout>
      <Dialog
        open={open}
        onClose={handleClose}
        maxWidth={'sm'}
        fullWidth>
        <DialogTitle id="alert-dialog-title">{t('pricing.contactSales')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>{t('pricing.contactSalesDesc')}</span>
            &nbsp;
            <span className={classes.dialogText}>sales@openuse.io</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            <span className={classes.dialogText}>{t('pricing.gotIt')}</span>
          </Button>
        </DialogActions>
      </Dialog>
    </CommonLayout>
  )
}
