import HeaderBar from "./Header";
import FooterBar from "./Footer";
import React from "react";

export default function CommonLayout(props) {
  return (
    <div style={{width: '100%', height: '100vh', display: 'flex', flexDirection: 'column'}}>
      <HeaderBar />
      <div style={{flexGrow: 1}}>
        {props.children}
      </div>
      <FooterBar />
    </div>
  )
}
