import {useTranslation} from "react-i18next";
import CommonLayout from "../../components/CommonLayout";
import {Grid} from "@material-ui/core";
import React from "react";
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";
import img1x2 from "../../assets/img/solution_1@3x.png";
import img2x2 from "../../assets/img/solution_2@3x.png";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    padding: '80px',
    backgroundColor: theme.currentTheme.background,
    [theme.breakpoints.down('sm')]: {
      padding: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 20px 50px 20px',
    },
  },
  section1: {
    flexDirection: 'row-reverse',
    marginBottom: '50px',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      marginBottom: '30px',
    },
  },
  sectionDesc: {
    color: theme.currentTheme.colors[2],
    marginTop: '30px',
    [theme.breakpoints.down('xs')]: {
      marginTop: '10px',
    },
  },
  imgWrapper: {
    textAlign: 'center',
  },
  img: {
    width: '100%',
    height: '100%',
    objectFit: 'scale-down',
    maxWidth: '530px',
    maxHeight: '678px',
    [theme.breakpoints.down('xs')]: {
      width: '318px',
      height: '407px',
    },
  },
}));

export default function Solutions() {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <CommonLayout>
      <Grid className={classes.pageContainer}>
        <Grid container className={classes.section1}>
          <Grid item xs={12} sm={5} md={5} lg={4} className={classes.imgWrapper} style={{marginBottom: '20px'}}>
            <img src={img1x2} className={classes.img} alt=''/>
          </Grid>
          <Grid item xs={12} sm={1} md={1} lg={2}>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant={'h2'}>{t('solutions.sectionTitle1')}</Typography>
            <Typography variant={'subtitle1'} className={classes.sectionDesc}>{t('solutions.sectionDesc1')}</Typography>
          </Grid>
        </Grid>
        <Grid container>
          <Grid item xs={12} sm={5} md={5} lg={4} className={classes.imgWrapper}>
            <img src={img2x2} className={classes.img} alt=''/>
          </Grid>
          <Grid item xs={12} sm={1} md={1} lg={2}>
          </Grid>
          <Grid item xs={12} sm={6} md={6} lg={6}>
            <Typography variant={'h2'}>{t('solutions.sectionTitle2')}</Typography>
            <Typography variant={'subtitle1'} className={classes.sectionDesc}>{t('solutions.sectionDesc2')}</Typography>
          </Grid>

        </Grid>
      </Grid>
    </CommonLayout>
  )
}
