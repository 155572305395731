import React, {useState} from "react";
import {useTranslation} from 'react-i18next';

export default function FormFunction() {
  const {t} = useTranslation();
  const [fields, setFields] = useState({
    oldPassword: {
      title: t('security.oldPassword'),
      name: 'oldPassword',
      value: '',
      valid: true,
      rules: {
        required: true,
        requiredMessage: t('register.passMsg1'),
        regex: '(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}',
        regexMessage: t('register.passMsg2'),
      }
    },
    newPassword: {
      title: t('security.newPassword'),
      name: 'newPassword',
      value: '',
      valid: true,
      rules: {
        required: true,
        requiredMessage: t('register.passMsg1'),
        regex: '(?=.*?[A-Za-z])(?=.*?[0-9]).{8,}',
        regexMessage: t('register.passMsg2'),
      }
    },
    rePassword: {
      title: t('security.rePassword'),
      name: 'rePassword',
      value: '',
      valid: true,
      rules: {
        required: true,
        requiredMessage: t('register.repsdMsg'),
      }
    },
  });

  const handleChange = function (fieldName, newValue) {
    const data = {...fields}
    const field = data[fieldName];
    field.value = newValue;
    field.valid = checkValid(field, newValue);
    setFields(data)
  }

  const isFormValid = function () {
    let formValid = true
    const data = {...fields}
    Object.keys(data).forEach((key) => {
      formValid = checkValid(data[key], data[key].value) && formValid;
    })
    setFields(data);
    return formValid;
  }

  const checkValid = function (field, newValue) {
    let valid = checkRequired(field, newValue);

    if (valid) {
      if (field.name === 'oldPassword' || field.name === 'newPassword') {
        valid = checkRegex(field, newValue);
      } else if (field.name === 'rePassword') {
        valid = checkSame();
      }
    }

    return valid;
  }

  const checkRequired = function (field, newValue) {
    let isRequired = field.rules && field.rules.required;
    let valid = true;
    if (isRequired) {
      if (typeof newValue === "string") {
        valid = !!newValue && !!newValue.trim();
      } else if (typeof newValue === "undefined") {
        valid = false;
      } else if (typeof newValue === "object") {
        valid = !!newValue;
      }
    }
    if (!valid) {
      field.errorMsg = field.rules.requiredMessage;
    } else {
      field.errorMsg = '';
    }
    field.valid = valid;
    return valid;
  }

  const checkRegex = function (field, newValue) {
    let valid = true;
    const strongRegex = new RegExp(field.rules.regex)
    if (!strongRegex.test(newValue)) {
      valid = false;
      field.errorMsg = field.rules.regexMessage;
    }
    return valid;
  }

  const checkSame = function () {
    let valid = true;
    if (fields.newPassword.value !== fields.rePassword.value) {
      valid = false;
      fields.rePassword.errorMsg = t('register.errorMsg3');
    }
    return valid;
  }

  return {
    fields: fields,
    setFields: setFields,
    isFormValid: isFormValid,
    handleChange: handleChange,
  }
}
