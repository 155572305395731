import {getLoginURL, axiosCore, axiosIam} from './axiosHelper';

const redirectToLogin = function () {
  window.location.href = getLoginURL();
}

const logout = function (token) {

  const url = process.env.REACT_APP_IAM_SERVICE_URL + '/logout';

  return new Promise((resolve, reject) => {
    axiosCore.request({
      url: url,
      method: 'get',
      headers: {
        'content-type': 'application/json',
        'accept': '*/*'
      },
      params: {
        'token': token
      }
    }).then(function (result) {
      if (result && (result.status === 200 || result.status === 201)) {
        resolve(result)
      } else {
        throw result;
      }
    }).catch(function (e) {
      reject(e);
    });
  })

}

const checkToken = function (token) {

  const url = process.env.REACT_APP_IAM_SERVICE_URL + '/oauth/check_token';

  return new Promise((resolve, reject) => {
    axiosCore.request({
      url: url,
      method: 'get',
      headers: {
        'x-access-token': token,
        'content-type': 'application/json',
        'accept': '*/*'
      },
      params: {
        'token': token
      }
    }).then(function (result) {
      if (result && (result.status === 200 || result.status === 201)) {
        resolve(result)
      } else {
        throw result;
      }
    }).catch(function (e) {
      reject(e);
    });
  })
}

const getUser = (email) => {
  const url = '/v1/users/' + email;
  console.log('getUser with username: ' + email);
  return new Promise((resolve, reject) => {
    axiosIam.get(url).then(result => {
      resolve(result.data);
    }).catch(e => {
      reject(e);
    })
  })
}

const sendPinCode = (email, _) => {
  const url = '/v1/totp/';
  const data = {
    'username': email,
  }
  return new Promise((resolve, reject) => {
    axiosIam.post(url, data).then(result => {
      resolve(result.data);
    }).catch(e => {
      reject(e);
    })
  })
}

const checkPinCode = (email, pinCode) => {
  const url = '/v1/totp/check/';
  const data = {
    'totp': pinCode,
    'username': email,
  }
  return new Promise((resolve, reject) => {
    axiosIam.post(url, data).then(result => {
      resolve(result.data);
    }).catch(e => {
      reject(e);
    })
  })
}

const register = function (params) {
  let url = '/rfq/public/user/register';

  return new Promise((resolve, reject) => {
    axiosCore.request({
      url: url,
      method: 'post',
      headers: {
        'content-type': 'application/json',
        'accept': '*/*'
      },
      data: params
    }).then(function (result) {
      console.log('register: ', result)
      if (result && result.data) {
        resolve(result.data)
      } else {
        throw result;
      }
    }).catch(function (e) {
      console.error(e)
      reject(e);
    });
  })
}

const changePassword = function (token, originalPassword, newPassword) {

  const url = process.env.REACT_APP_IAM_SERVICE_URL + '/v1/users/password';

  const data = {
    'original_password': originalPassword,
    'password': newPassword
  }

  return new Promise((resolve, reject) => {
    axiosCore.request({
      url: url,
      method: 'patch',
      headers: {
        'content-type': 'application/json',
        'accept': '*/*',
        'x-access-token': 'Bearer ' + token
      },
      data: data
    }).then(function (result) {
      resolve({
        code: result.data.code,
        status: result.data.status,
      });
    }).catch(function (e) {
      console.error('changePassword: ', e);
      if (e.response && e.response.data && e.response.data.code && e.response.data.status) {
        resolve({
          code: e.response.data.code,
          status: e.response.data.status,
        });
      } else  {
        reject(e);
      }
    });
  });

}

export default {
  redirectToLogin,
  logout,
  checkToken,
  register,
  getUser,
  sendPinCode,
  checkPinCode,
  changePassword,
}
