import Box from '@material-ui/core/Box'
import {useHistory} from 'react-router-dom';
import {useState, useEffect} from 'react';
import {useMediaQuery} from '@material-ui/core';
import useToken from '../hooks/useToken';
import iamService from '../services/iamService';
import {useTranslation} from 'react-i18next';
import moment from "moment";
import "moment/locale/zh-cn";
import NormalHeader from "./NormalHeader";
import MobileHeader from "./MobileHeader";
import {useDarkMode} from "../hooks/useDarkMode";

moment.locale("en");

export default function HeaderBar() {
  const {i18n} = useTranslation();
  let {token, setToken} = useToken();
  const history = useHistory();
  const [theme] = useDarkMode();

  const isMobile = useMediaQuery((theme) => {
    return theme.breakpoints.down('xs');
  });

  useEffect(() => {
    const localLanguage = window.localStorage.getItem('language');
    const defaultLang = (navigator.language || navigator.browserLanguage).toLowerCase();
    if (localLanguage) {
      i18n.changeLanguage(localLanguage);
    } else if (defaultLang.indexOf('en') !== -1) {
      i18n.changeLanguage('en');
    } else {
      i18n.changeLanguage('zh');
    }

  }, []);

  const [anchorEl, setAnchorEl] = useState(null);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const signInHandler = () => {
    iamService.redirectToLogin();
  }

  const signUpHandler = () => {
    history.push('/register');
  }

  const signOutHandler = () => {
    if (token) {
      iamService.logout(token['access_token']).then((_) => {
        setToken('');
        window.location.href = '/';
      });
    }
  }

  const jump2Home = () => {
    const homeURL = '/';
    history.push(homeURL);
    handleClose();
  }
  const jump2Solutions = () => {
    const pageUrl = '/solutions';
    history.push(pageUrl);
    handleClose();
  }
  const jump2Enterprise = () => {
    const pageUrl = '/enterprise';
    history.push(pageUrl);
    handleClose();
  }
  const jump2Pricing = () => {
    // window.open('/pricing');
    history.push('/pricing');
    handleClose();
  }

  const gotoProfilePage = () => {
    history.push('/profile');
    handleClose();
  }

  const gotoSubscriptionPage = () => {
    history.push('/subscription');
    handleClose();
  }

  const gotoSecurityPage = () => {
    history.push('/security');
    handleClose();
  }

  const jump2Download = () => {

    let anchorElement = document.getElementById('app_download');
    if(anchorElement) {
      anchorElement.scrollIntoView({behavior: 'smooth'});
    }

  }

  const headerProps = {
    jump2Home: jump2Home,
    jump2Solutions: jump2Solutions,
    jump2Enterprise: jump2Enterprise,
    jump2Pricing: jump2Pricing,
    signUpHandler: signUpHandler,
    signInHandler: signInHandler,
    signOutHandler: signOutHandler,
    gotoProfilePage: gotoProfilePage,
    gotoSubscriptionPage: gotoSubscriptionPage,
    gotoSecurityPage: gotoSecurityPage,
    jump2Download: jump2Download,
  }

  return (
    <Box style={{width: '100%'}} id="header">
      {!isMobile && <NormalHeader handlers={headerProps} token={token} darkMode={theme === 'dark'}/>}
      {isMobile && <MobileHeader handlers={headerProps} token={token} darkMode={theme === 'dark'}/>}
    </Box>
  )
}
