import {TOGGLE_THEME} from './actions';

const initState = '';
export const toggleTheme = (state = initState, action) => {
    console.log('state： ' + state + ' type: ' + action.type);
    switch(action.type) {
        case TOGGLE_THEME:
            return action.data;
        default:
            return state;
    }

    // return state;
}
