import {useState} from "react";

export default function FormFunction() {
  const [fields, setFields] = useState({
    paymentType: {
      value: '',
    },
    cardLast4: {
      value: '',
    },
    periodEnd: {
      value: '',
    },
    invoice: {
      value: [],
    },
    username: {
      value: '',
    },
    active: {
      value: false,
    },
    nextPaymentTile: {
      value: '',
    }
  });

  return {
    fields: fields,
    setFields: setFields,
  }

}
