import {useTranslation} from "react-i18next";
import {Grid} from "@material-ui/core";
import {makeStyles} from '@material-ui/core/styles';
import featurePhoneImg from '../../assets/img/homeFeaturePhone@3x.png'

const useStyles = makeStyles((theme) => ({
  featureSection: {
    color: 'white',
    padding: '90px 40px 40px 56px',
    background: '-webkit-linear-gradient(top, #12498e, #071832)',
    marginTop: '50px',
    '@media (max-width:600px)': {
      padding: '30px 20px',
    },
  },
  featureTitle: {
    fontSize: '48px',
    textAlign: 'center',
    marginBottom: '38px',
    '@media (max-width:600px)': {
      fontSize: '32px',
    },
  },
  featureWrapper: {
    marginTop: '38px',
    '@media (max-width:600px)': {
      fontSize: '18px',
    },
  },
  featureSectTitle: {
    fontSize: '24px',
    fontWeight: '600',
  },
  featureSectDesc: {
    fontSize: '18px',
    color: '#939393',
    marginTop: '20px'
  },
  featureNum: {
    width: '32px',
    height: '32px',
    borderRadius: '18px',
    backgroundColor: '#7c93b1',
    marginRight: '18px',
    fontSize: '24px',
    float: 'left',
    textAlign: 'center',
  },
  featureText: {
    marginLeft: '48px',
  },
  featurePhone: {
    textAlign: 'center',
    '& img': {
      width: '297px',
      height: '536px',
      '@media (max-width:600px)': {
        width: '200px',
        height: '360px',
        marginTop: '20px',
      },
    }
  },
}));

export default function FeaturesSection() {
  const {t} = useTranslation();
  const classes = useStyles();

  return (
    <Grid container direction={'column'} className={classes.featureSection}>
      <div className={classes.featureTitle}>{t('home.featureSectionTitle')}</div>

      <Grid container>
        <Grid item xs={12} sm={7}>
          <div className={classes.featureWrapper}>
            <div className={classes.featureNum}>1
            </div>
            <div className={classes.featureText}>
              <div className={classes.featureSectTitle}>{t('home.feature1Title')}</div>
              <div className={classes.featureSectDesc}>{t('home.feature1Desc')}</div>
            </div>
          </div>
          <div className={classes.featureWrapper}>
            <div className={classes.featureNum}>
              <span>2</span>
            </div>
            <div className={classes.featureText}>
              <div className={classes.featureSectTitle}>{t('home.feature2Title')}</div>
              <div className={classes.featureSectDesc}>{t('home.feature2Desc')}</div>
            </div>
          </div>
          <div className={classes.featureWrapper}>
            <div className={classes.featureNum}>
              <span>3</span>
            </div>
            <div className={classes.featureText}>
              <div className={classes.featureSectTitle}>{t('home.feature3Title')}</div>
              <div className={classes.featureSectDesc}>{t('home.feature3Desc')}</div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} sm={5} className={classes.featurePhone}>
          <img src={featurePhoneImg} alt=''/>
        </Grid>
      </Grid>

    </Grid>
  )
}
