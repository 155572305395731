import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import React from "react";
import CommonLayout from "../../components/CommonLayout";
import cancelSubscriptionSuccessfulImg from "../../assets/img/cancel_subscription_successful.png";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    paddingBottom: '80px',
    paddingTop: '20px',
    backgroundColor: theme.currentTheme.background,
    [theme.breakpoints.down('sm')]: {
      padding: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 20px 50px 20px',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title1: {
    fontSize: '36px',
    fontFamily: 'RobotoRegular',
    '@media (max-width:600px)': {
      fontSize: '22px',
    },
    fontWeight: '300',
    textAlign: 'center',
    marginBottom: '50px',
  },
  image: {
    '@media (max-width:600px)':  {
      width: '247px',
      height: '170px',
    },
  },
  description: {
    fontFamily: 'RobotoRegular',
    fontSize: '18px',
    maxWidth: '400px',
    marginTop: '20px',
    '@media (max-width:600px)':  {
      maxWidth: '300px',
      fontSize: '16px',
    },
  }
}));

export default function CancelSubscriptionSuccessful() {
  const {t} = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  return (
    <CommonLayout>
      <div className={classes.pageContainer}>
        <div className={classes.title1}>
          {t('subscription.cancelTitle')}
        </div>
        <div>
          <img src={cancelSubscriptionSuccessfulImg} alt="cancel subscription successful" className={classes.image}/>
        </div>

        <div className={classes.description}>
          {t('subscription.cancelSubscriptionSuccessfulDesc').replace('{last_date}', history.location.state.lastDate)}
        </div>

      </div>
    </CommonLayout>
  );
}
