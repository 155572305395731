import {axiosCore} from './axiosHelper';

const getPaymentStatus = function (token, start, end) {
  console.log('getPaymentStatus with start:   ' + start + ', end: ' + end);

  let url = '/payment/payment-service/users/payment';
  if (start && end) {
    url += '?start=' + start + '&end=' + end;
  }

  return new Promise((resolve, reject) => {
    axiosCore.request({
      url: url,
      method: 'get',
      headers: {
        'x-access-token': 'Bearer ' + token,
      }
    }).then(result => {
      resolve(result.data);
    }).catch(e => {
      reject(e);
    })
  });
};

const cancelSubscription = (token) => {
  const url = '/payment/payment-service/stripe/users/cancel-subscription';
  return new Promise((resolve, reject) => {
    axiosCore.request({
      url: url,
      method: 'post',
      headers: {
        'x-access-token': 'Bearer ' + token,
      }
    }).then(result => {
      resolve(result.data);
    }).catch(e => {
      reject(e);
    })
  });
}

export default {
  getPaymentStatus,
  cancelSubscription,
}
