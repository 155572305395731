import {IconButton, Tooltip} from "@material-ui/core";
import Brightness7Icon from "@material-ui/icons/Brightness7";
import Brightness4Icon from "@material-ui/icons/Brightness4";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import {useDarkMode} from "../hooks/useDarkMode";

const useStyles = makeStyles(() => ({
  iconButton: {
    color: '#FFFFFF',
  },
}));

export default function ThemeToggle() {
  const classes = useStyles();
  const {t} = useTranslation();

  const [theme, toggleTheme] = useDarkMode();

  return (
    <Tooltip title={theme === 'dark' ? t('toolbar.switchLight') : t('toolbar.switchDark')}>
      <IconButton className={classes.iconButton} onClick={toggleTheme}>
        {theme === 'dark' ? <Brightness7Icon/> : <Brightness4Icon/>}
      </IconButton>
    </Tooltip>
  )
}
