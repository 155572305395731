import {useTranslation} from "react-i18next";
import {makeStyles} from '@material-ui/core/styles';
import {Grid, Button} from '@material-ui/core';
import phone1Img from '../../assets/img/homePhone1@3x.png'
import phone2Gif from '../../assets/img/homePhone2.gif'
import phone3Gif from '../../assets/img/homePhone3.gif'
import phone4Gif from '../../assets/img/homePhone4.gif'
import FeaturesSection from "./FeaturesSection";
import React from "react";
import CommonLayout from "../../components/CommonLayout";
import {useHistory} from "react-router-dom";
import imgApple from "../../assets/img/homeDownload1@3x.png";
import imgGoogle from "../../assets/img/homeDownload2@3x.png";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    backgroundColor: theme.currentTheme.background,
  },
  pageTitleSection: {
    paddingLeft: '10%',
    paddingRight: '10%',
  },
  pageTitle: {
    fontSize: '40px',
    color: theme.currentTheme.text,
    '@media (max-width:960px)': {
      marginTop: '60px',
      fontSize: '30px',
    },
  },
  pageDesc: {
    fontSize: '20px',
    color: theme.currentTheme.colors[2],
    marginTop: '15px'
  },
  buttonSize: {
    objectFit: 'contain',
    width: '45%',
    maxWidth: '160px'
  },
  signUpEmail: {
    marginTop: '35px',
    width: 'fit-content',
    padding: '16px 48px',
  },
  rightPhoneBG: {
    backgroundColor: '#240935 !important',
    '@media (max-width:960px)': {
      display: 'none'
    },
  },
  phone: {
    width: '450px',
    height: '645px',
    marginLeft: '-50px',
    '@media (max-width:960px)': {
      display: 'none'
    },
  },
  sectionText: {
    paddingRight: '50px',
    paddingLeft: '50px',
    '@media (max-width:600px)': {
      marginTop: '30px',
    },
  },
  sectionTitle: {
    color: theme.currentTheme.colors[7],
    fontSize: '32px',
    fontWeight: 'bold',
    '@media (max-width:600px)': {
      marginBottom: '30px',
      fontSize: '24px',
    },
  },
  sectionDesc: {
    color: theme.currentTheme.colors[2],
    fontSize: '20px',
    marginTop: '15px',
    '@media (max-width:600px)': {
      marginBottom: '30px',
      fontSize: '16px',
      marginTop: '8px',
    },
  },
  section2: {
    marginTop: '80px'
  },
  leftPhoneWrapper: {
    borderRadius: '10px 80px 80px 10px',
    background: '-webkit-linear-gradient(top, #240a35, #071832)',
    justifyContent: 'center',
    '@media (max-width:600px)': {
      borderRadius: '0px',
      marginTop: '30px'
    }
  },
  rightPhoneWrapper: {
    borderRadius: '80px 10px 10px 80px',
    background: '-webkit-linear-gradient(top, #240a35, #071832)',
    justifyContent: 'center',
    '@media (max-width:600px)': {
      borderRadius: '0px',
      marginTop: '30px'
    }
  },
  phone2: {
    width: '536px',
    height: '674px',
    backgroundImage: `url(${phone2Gif})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    '@media (max-width:600px)': {
      width: '268px',
      height: '337px',
      marginBottom: '30px',
    },
    '@media (max-width:960px)': {
      width: '268px',
      height: '337px',
    },
  },
  section3: {
    marginTop: '80px',
    direction: 'column-reverse'
  },
  phone3: {
    width: '536px',
    height: '674px',
    backgroundImage: `url(${phone3Gif})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    '@media (max-width:600px)': {
      width: '268px',
      height: '337px',
      marginBottom: '30px',
    },
    '@media (max-width:960px)': {
      width: '268px',
      height: '337px',
    },
  },
  section4: {
    marginTop: '80px'
  },
  phone4: {
    width: '536px',
    height: '674px',
    backgroundImage: `url(${phone4Gif})`,
    backgroundRepeat: "no-repeat",
    backgroundSize: "100% 100%",
    '@media (max-width:600px)': {
      width: '268px',
      height: '337px',
      marginBottom: '30px',
    },
    '@media (max-width:960px)': {
      width: '268px',
      height: '337px',
    },
  },
  avatarBox: {
    cursor: 'pointer'
  }
}));

export default function Home() {
  const {t} = useTranslation();
  const classes = useStyles();
  const history = useHistory();

  return (
    <CommonLayout>
      <Grid container className={classes.pageContainer}>
        <Grid container alignItems={'center'}>
          <Grid item xs={12} sm={12} md={7} className={classes.pageTitleSection}>
            <h1 className={classes.pageTitle}>{t('home.pageTitle')}</h1>
            <div className={classes.pageDesc}>{t('home.pageDesc')}</div>
            <div style={{marginTop: '30px'}}>
              <a href="https://apps.apple.com/us/app/open-rfq/id1641606984">
                <img src={imgApple} alt="Download on the App Store" className={classes.buttonSize} />
              </a>
              <a href="https://play.google.com/store/apps/details?id=net.opendais.rfq" style={{marginLeft: '20px'}}>
                <img src={imgGoogle} alt="Get it on Google Play" className={classes.buttonSize}/>
              </a>
            </div>
          </Grid>

          <Grid item xs={12} sm={12} md={5} className={classes.rightPhoneBG}>
            <img src={phone1Img} className={classes.phone} alt=''/>
          </Grid>
        </Grid>

        <Grid container alignItems={'center'} className={classes.section2}>
          <Grid item xs={12} sm={6} md={6} className={classes.leftPhoneWrapper}>
            <div className={classes.phone2}/>
          </Grid>

          <Grid item xs={12} sm={6} md={6} className={classes.sectionText}>
            <div className={classes.sectionTitle}>{t('home.sect1Title')}</div>
            <div className={classes.sectionDesc}>{t('home.sect1Desc')}</div>
          </Grid>

        </Grid>

        <Grid container direction={'row-reverse'} alignItems={'center'} className={classes.section3}>
          <Grid item xs={12} sm={6} md={6} className={classes.rightPhoneWrapper}>
            <div className={classes.phone3}/>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.sectionText}>
            <div className={classes.sectionTitle}>{t('home.sect2Title')}</div>
            <div className={classes.sectionDesc}>{t('home.sect2Desc')}</div>
          </Grid>

        </Grid>

        <Grid container direction={'row'} alignItems={'center'} className={classes.section4}>
          <Grid item xs={12} sm={6} md={6} className={classes.leftPhoneWrapper}>
            <div className={classes.phone4}/>
          </Grid>
          <Grid item xs={12} sm={6} md={6} className={classes.sectionText}>
            <div className={classes.sectionTitle}>{t('home.sect3Title')}</div>
            <div className={classes.sectionDesc}>{t('home.sect3Desc')}</div>
          </Grid>
        </Grid>


        <FeaturesSection/>

      </Grid>

    </CommonLayout>
  )
}
