import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import React, {useEffect, useState} from "react";
import {Button, Grid} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import ReactCodeInput from "react-code-input";
import iamService from "../../services/iamService";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    padding: '8% 32%',
    [theme.breakpoints.down('lg')]: {
      padding: '8% 30%',
    },
    [theme.breakpoints.down('md')]: {
      padding: '8% 25%',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '80px 50px',
    },
  },
  title: {
    textAlign: 'center',
  },
  desc: {
    display: "inline-block",
  },
  error: {
    color: 'red',
    marginLeft: '5px',
  }
}));

export default function CodeVerifySection(props) {
  const classes = useStyles();
  const {t} = useTranslation();
  const email = props.email;
  const [code, setCode] = useState('');
  const [errorInfo, setErrorInfo] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [disableSend, setDisableSend] = useState(false);

  useEffect(() => {
    sendCodeToEmail();
  }, []);

  useEffect(() => {
    if (countdown > 0) {
      setDisableSend(true);
      setTimeout(() => {
        setCountdown(countdown - 1)
      }, 1000)
    } else {
      setDisableSend(false);
    }
  }, [countdown]);

  const onCodeChange = (newValue) => {
    console.log('onCodeChange newValue: ', newValue);
    setCode(newValue);
    setErrorInfo('');
    if (newValue && newValue.trim()) {
      let trimValue = newValue.trim()
      if (trimValue.length >= 6) {
        checkPinCode(trimValue);
      }
    }
  }

  const getButtonText = () => {
    let text = t('register.resendCode');
    if (countdown > 0) {
      text += ' (' + countdown + 's)';
    }
    return text;
  }

  const sendCodeToEmail = () => {
    if (!disableSend) {
      setCountdown(60);
      iamService.sendPinCode(email).then((_) => {
      })
    }
  }

  const checkPinCode = (pinCode) => {
    iamService.checkPinCode(email, pinCode).then((data) => {
      if (data && data.result && data.status === '200') {
        props.onContinue();
      } else {
        setErrorInfo('Pin code is not correct!');
      }
    })
  }

  return (
    <Grid container direction={'column'} alignItems={'center'} spacing={4} className={classes.pageContainer}>
      <Grid item>
        <Typography variant={'subtitle1'}
                    className={classes.title}>{t('register.codePageTitle', {user: email})}</Typography>
      </Grid>
      <Grid item>
        <ReactCodeInput type='text' fields={6} value={code} onChange={onCodeChange}></ReactCodeInput>
        <Typography variant={'body2'} className={classes.error}>{errorInfo}</Typography>
      </Grid>
      <Grid item>
        <Typography variant={'body2'} className={classes.desc}>{t('register.codePageHint')}</Typography>
        <Button variant={'text'} onClick={sendCodeToEmail} disabled={disableSend}>{getButtonText()}</Button>
      </Grid>
    </Grid>
  )
}
