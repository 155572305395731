import {Grid, TextField, Typography} from "@material-ui/core";
import PasswordWithHint from "../../components/PasswordWithHint";
import PasswordInput from "../../components/PasswordInput";
import React, {useEffect, useState} from "react";
import FormFunction from "./FormFunction";
import iamService from "../../services/iamService";
import useStyle from "./RegisterPage.style";
import {useTranslation} from "react-i18next";
import LoadingButton from "../../components/LoadingButton";

export default function RegisterInfoSection(props) {
  const classes = useStyle();
  const {t} = useTranslation();

  const userEmail = props.email;
  const [formInfo, setFormInfo] = useState({
    valid: null,
    msg: null
  });

  const [busy, setBusy] = useState(false);
  const {fields, setFields, isFormValid, handleChange} = FormFunction();

  useEffect(() => {
    let data = {...fields};
    data.email.value = userEmail;
    data.email.editable = false;

    setFields(data);
  }, []);

  const onSubmit = function () {
    if (busy) {
      return;
    }
    if (!isFormValid()) {
      console.warn('Form is invalid!');
      return;
    }

    setBusy(true);

    let params = {
      email: fields.email.value,
      password: fields.password.value,
      firstName: fields.firstName.value,
      lastName: fields.lastName.value,
      companyName: fields.companyName.value,
    }

    sendRequest(params);
  }

  const sendRequest = function (params) {
    iamService.register(params).then((data) => {

      if (data.code === 200) {
        props.onContinue();
      } else if (data.code === 409 || data.code === 1200) {
        setFormInfo({
          valid: "error",
          msg: t('register.userExist')
        })
      }

      setBusy(false);

    }).catch((err) => {
      console.error('sendRequest: ', err);
      setFormInfo({
        valid: "error",
        msg: t('register.unknownError')
      });

      setBusy(false);

    })
  }

  return (
    <div className={classes.container}>
      <Typography variant='h2' gutterBottom className={classes.pageTitle}>
        {t('register.startWork')}
      </Typography>
      <form className={classes.form}>
        <Grid container spacing={4}>
          <Grid item xs={6}>
            <TextField fullWidth
                       label={fields.firstName.title}
                       variant='outlined'
                       autoComplete='none'
                       value={fields.firstName.value}
                       onChange={(e) => {
                         handleChange(fields.firstName.name, e.target.value)
                       }}
                       error={!fields.firstName.valid}
                       helperText={fields.firstName.errorMsg}
            >
            </TextField>
          </Grid>
          <Grid item xs={6}>
            <TextField fullWidth
                       label={fields.lastName.title}
                       variant='outlined'
                       autoComplete='none'
                       value={fields.lastName.value}
                       onChange={(e) => {
                         handleChange(fields.lastName.name, e.target.value)
                       }}
                       error={!fields.lastName.valid}
                       helperText={fields.lastName.errorMsg}>

            </TextField>
          </Grid>
        </Grid>

        <TextField fullWidth
                   label={fields.email.title}
                   variant='outlined'
                   autoComplete='none'
                   disabled={fields.email.editable === false}
                   value={fields.email.value}
                   onChange={(e) => {
                     handleChange(fields.email.name, e.target.value)
                   }}
                   error={!fields.email.valid}
                   helperText={!fields.email.valid && fields.email.errorMsg}
        >
        </TextField>

        <PasswordWithHint field={fields.password}
                          onChange={(value) => {
                            handleChange(fields.password.name, value)
                          }}></PasswordWithHint>
        <PasswordInput title={fields.repassword.title}
                       initValue={fields.repassword}
                       valid={fields.repassword.valid}
                       errorMsg={fields.repassword.errorMsg}
                       onChange={(e) => {
                         handleChange(fields.repassword.name, e.target.value)
                       }}
        ></PasswordInput>

        <TextField fullWidth
                   label={fields.companyName.title}
                   variant='outlined'
                   autoComplete='none'
                   value={fields.companyName.value}
                   onChange={(e) => {
                     handleChange(fields.companyName.name, e.target.value)
                   }}
                   error={!fields.companyName.valid}
                   helperText={fields.companyName.errorMsg}
        >
        </TextField>

        <Typography variant='body2' className={classes.errorText} gutterBottom>{formInfo.msg}</Typography>
        <LoadingButton variant="contained" color="primary"
                className={classes.submitButton}
                       isLoading={busy}
                onClick={onSubmit}>
          {t('register.createAccount')}
        </LoadingButton>

      </form>
    </div>

  )
}
