import './RegisterPage.style'
import React, {useEffect, useState} from "react";
import SimplifiedHeader from "../../components/SimplifiedHeader";
import EmailVerifySection from "./EmailVerifySection";
import CodeVerifySection from "./CodeVerifySection";
import RegisterInfoSection from "./RegisterInfoSection";
import {useHistory} from "react-router-dom";
import {makeStyles} from "@material-ui/core/styles";
import {Alert} from "@material-ui/lab";
import {Snackbar} from "@material-ui/core";
import {useTranslation} from "react-i18next";
import iamService from "../../services/iamService";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    width: '100%',
  }
}));

export default function RegisterPage(props) {
  const classes = useStyles();
  const history = useHistory();
  const { t } = useTranslation();
  const [openSuccess, setOpenSuccess] = useState(false);

  const [step, setStep] = useState(1);
  const [email, setEmail] = useState('');
  const headerMode = 'SIGN_UP';

  useEffect(() => {
  }, []);

  const onEmailInputted = function (email) {
    setEmail(email);
    setStep(2);
  }

  const onCodeVerified = function () {
    setStep(3);
  }

  const onRegistered = function () {
    setOpenSuccess(true);
  }

  const handleSuccessClose = () => {
    setOpenSuccess(false);
    iamService.redirectToLogin();
  }

  return (
    <div className={classes.pageContainer}>
      <SimplifiedHeader mode={headerMode}></SimplifiedHeader>

      {step === 1 && <EmailVerifySection onContinue={onEmailInputted}/>}
      {step === 2 && <CodeVerifySection email={email} onContinue={onCodeVerified}/>}
      {step === 3 && <RegisterInfoSection email={email} onContinue={onRegistered}/>}

      <Snackbar open={openSuccess} autoHideDuration={6000} onClose={handleSuccessClose}>
        <Alert onClose={handleSuccessClose} severity="success">
          <span className={classes.alertTitle}>{t('register.successContent1')}, {t('register.gotoSign')}</span>
        </Alert>
      </Snackbar>

    </div>

  )
}
