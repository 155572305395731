import {useTranslation} from "react-i18next";
import {useHistory} from "react-router-dom";
import useToken from "../../hooks/useToken";
import {makeStyles} from "@material-ui/core/styles";
import CommonLayout from "../../components/CommonLayout";
import {DataGrid} from '@material-ui/data-grid';
import React, {useState} from "react";
import moment from "moment";
import {
  Backdrop,
  Button,
  CircularProgress,
  Snackbar,
  Tooltip,
  useMediaQuery,
  ListItem,
  ListItemText,
  ListItemIcon
} from "@material-ui/core";
import DateFnsUtils from '@date-io/date-fns'
import IconButton from "@material-ui/core/IconButton";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import SearchIcon from '@material-ui/icons/Search';
import userService from "../../services/userService";
import {Alert} from "@material-ui/lab";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {FixedSizeList} from 'react-window';

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    paddingBottom: '80px',
    paddingTop: '20px',
    backgroundColor: theme.currentTheme.background,
    [theme.breakpoints.down('sm')]: {
      padding: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 20px 50px 20px',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title1: {
    fontSize: '36px',
    fontFamily: 'RobotoRegular',
    '@media (max-width:600px)': {
      fontSize: '22px',
    },
    fontWeight: '300',
    textAlign: 'center',
    marginBottom: '50px',
  },
  dialogButtonText: {
    color: theme.currentTheme.colors[8],
    '&:hover': {
      color: theme.currentTheme.colors[9],
    }
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#127bb0',
  },
  alertTitle: {
    fontFamily: 'RobotoRegular',
    fontSize: '16px',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '750px',
    '@media (max-width:600px)': {
      width: '100%',
    },
  },
  dataGrid: {
    height: 500,
    width: '750px',
    '@media (max-width:600px)': {
      width: '100%',
    },
  }
}));


export default function Invoices() {

  const isMobile = useMediaQuery((theme) => {
    return theme.breakpoints.down('xs');
  });

  const {t} = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const {token} = useToken();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [startDate, setStartDate] = useState(new Date(moment().add(-6, 'month').local().format('YYYY-MM-DD')));
  const [endDate, setEndDate] = useState(new Date(moment().local().format('YYYY-MM-DD')));
  const [openError, setOpenError] = useState(false);

  const rows = (list) => {

    let data = [];

    list.forEach((invoice) => {
      data.push({
        id: invoice.number,
        periodEnd: moment(invoice.periodEnd * 1000).local().format('YYYY-MM-DD HH:mm'),
        amount: '$ ' + invoice.total / 100 + '.00',
        action: invoice.hostedInvoiceUrl,
      });
    });

    return data;
  }

  const [invoiceList, setInvoiceList] = useState(rows(history.location.state.invoices));

  const gotoDetail = (url) => {
    window.open(url, '_blank');
  }

  const columns = [
    {field: 'id', headerName: t('subscription.invoiceNumber'), width: 200,},
    {
      field: 'periodEnd',
      headerName: t('subscription.paymentTime'),
      width: 200,
    },
    {
      field: 'amount',
      headerName: t('subscription.amountDue'),
      width: 150,
      sortable: false,
      filterable: false,
    },
    {
      field: 'action',
      headerName: t('common.actions'),
      width: 150,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <Tooltip title={t('subscription.view')}>
          <IconButton color='primary' onClick={() => gotoDetail(params.value)}>
            <ArrowForwardIosIcon/>
          </IconButton>
        </Tooltip>
      ),
    },
  ];

  function renderMobileInvoice(props) {

    const {index, style} = props;
    const invoice = invoiceList[index];

    return (
      <ListItem divider button alignItems="center"
                style={style} key={index}
                onClick={() => gotoDetail(invoice.action)}>
        <ListItemText primary={invoice.id}
                      secondary={
                        <React.Fragment>
                          {invoice.periodEnd}
                        </React.Fragment>
                      }
        />
        <ListItemText>
          {invoice.amount}
        </ListItemText>
        <ListItemIcon style={{cursor: 'pointer'}} onClick={() => gotoDetail(invoice.action)}>
          <ArrowForwardIosIcon/>
        </ListItemIcon>
      </ListItem>

    );

  }

  const search = () => {

    setOpenBackdrop(true);

    const startMoment = moment({
      year: startDate.getUTCFullYear(),
      month: startDate.getUTCMonth(),
      day: startDate.getUTCDate()
    }).startOf('second').format('x');

    const endMoment = moment({
      year: endDate.getUTCFullYear(),
      month: endDate.getUTCMonth(),
      day: endDate.getUTCDate()
    }).add(1, 'day').startOf('second').format('x');

    userService.getPaymentStatus(token.access_token, startMoment, endMoment).then((remoteResult) => {

      if (remoteResult.status === 200) {

        if (!remoteResult.data || Object.getOwnPropertyNames(remoteResult.data).length === 0) {
          setInvoiceList([]);
        }

        setInvoiceList(rows(remoteResult.data.invoice));

      } else {
        setOpenError(true);
      }

      setOpenBackdrop(false);

    }).catch((_) => {
      setOpenBackdrop(false);
      setOpenError(true);
    });

  }

  const handleErrorClose = () => {
    setOpenError(false);
  }

  function startTimePicker() {
    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disabledtoolbar="true"
        id="start-date-picker-dialog"
        format="yyyy-MM-dd"
        margin="normal"
        label={t('subscription.startDate')}
        inputVariant="outlined"
        value={startDate}
        onChange={date => setStartDate(date)}
        okLabel={<span className={classes.dialogButtonText}>OK</span>}
        cancelLabel={<span className={classes.dialogButtonText}>Cancel</span>}
      />

    </MuiPickersUtilsProvider>;
  }

  function endTimePicker() {
    return <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <KeyboardDatePicker
        disabledtoolbar="true"
        id="end-date-picker-dialog"
        format="yyyy-MM-dd"
        margin="normal"
        label={t('subscription.endDate')}
        inputVariant="outlined"
        value={endDate}
        onChange={date => setEndDate(date)}
        okLabel={<span className={classes.dialogButtonText}>OK</span>}
        cancelLabel={<span className={classes.dialogButtonText}>Cancel</span>}
      />
    </MuiPickersUtilsProvider>;
  }

  return (
    <CommonLayout>
      <div className={classes.pageContainer}>

        <div className={classes.title1}>
          {t('subscription.invoices')}
        </div>

        <div className={classes.content}>

          {
            isMobile &&
            <div style={{display: 'flex', flexDirection: 'column', alignItems: 'flex-start'}}>

              <div
                style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
                {startTimePicker()}
                &nbsp;-&nbsp;
                {endTimePicker()}

              </div>
              <Button
                style={{width: '100%', marginBottom: '20px'}}
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<SearchIcon/>}
                onClick={search}>{t('common.search')}</Button>

            </div>
          }

          {
            !isMobile &&
            <div style={{
              display: 'flex',
              width: '100%',
              flexDirection: 'row',
              alignItems: 'center',
              justifyContent: 'space-between'
            }}>
              <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center'}}>
                {startTimePicker()}
                &nbsp;-&nbsp;
                {endTimePicker()}
              </div>
              <Button
                variant="contained"
                color="primary"
                className={classes.button}
                startIcon={<SearchIcon/>}
                onClick={search}>{t('common.search')}</Button>
            </div>
          }

        </div>

        {

          isMobile &&
            <FixedSizeList height={400} width={'100%'} itemSize={60}
                           itemCount={invoiceList.length}>
              {renderMobileInvoice}
            </FixedSizeList>
        }

        {
          !isMobile && <div className={classes.dataGrid}>
            <DataGrid
              rows={invoiceList}
              columns={columns}
              pageSize={10}
              disableSelectionOnClick
            />
          </div>
        }


      </div>


      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit"/>
      </Backdrop>

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error">
          <span className={classes.alertTitle}>{t('common.failed')}</span>
        </Alert>
      </Snackbar>

    </CommonLayout>
  );

}
