import {makeStyles} from "@material-ui/core/styles";
import CommonLayout from "../../components/CommonLayout";
import {useTranslation} from "react-i18next";
import React, {useState} from "react";
import cancelSubscriptionImg from "../../assets/img/cancel_subscription.png";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Snackbar
} from "@material-ui/core";
import {useHistory} from "react-router-dom";
import userService from "../../services/userService";
import useToken from "../../hooks/useToken";
import CircularProgress from "@material-ui/core/CircularProgress";
import {Alert} from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    paddingBottom: '80px',
    paddingTop: '20px',
    backgroundColor: theme.currentTheme.background,
    [theme.breakpoints.down('sm')]: {
      padding: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 20px 50px 20px',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title1: {
    fontSize: '36px',
    fontFamily: 'RobotoRegular',
    '@media (max-width:600px)': {
      fontSize: '22px',
    },
    fontWeight: '300',
    textAlign: 'center',
    marginBottom: '50px',
  },
  submitButton: {
    width: '400px',
    height: '48px !important',
    marginTop: '30px',
    fontFamily: 'RobotoRegular',
    fontSize: '16px',
    backgroundColor: '#E03529FF', // E03529FF
    '&:hover': {
      backgroundColor: '#b60d04',
      color: 'white',
    },
    color: 'white',
    '@media (max-width:600px)':  {
      maxWidth: '300px',
    },
  },
  buttonProgress: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  dialogText: {
    fontWeight: '600',
    color: theme.currentTheme.colors[8],
  },
  image: {
    '@media (max-width:600px)':  {
      width: '205px',
      height: '161px',
    },
  },
  description: {
    fontFamily: 'RobotoRegular',
    fontSize: '18px',
    maxWidth: '400px',
    marginTop: '20px',
    '@media (max-width:600px)':  {
      maxWidth: '300px',
      fontSize: '16px',
    },
  }
}));

export default function CancelSubscription() {

  const {t} = useTranslation();
  const classes = useStyles();
  const history = useHistory();
  const {token} = useToken();

  const [cancelConfirmOpen, setCancelConfirmOpen] = useState(false);
  const [loading, setLoading] = React.useState(false);
  const [openError, setOpenError] = useState(false);
  const [contactSalesOpen, setContactSalesOpen] = useState(false);
  const showCancelSubscriptionDialog = () => {

    if (history.location.state.paymentStatus === 'enterprise') {
      setContactSalesOpen(true);
    } else {
      setCancelConfirmOpen(true);
    }

  }

  const handleCancelConfirmClose = () => {
    setCancelConfirmOpen(false);
  }

  const handleCancelSubscription = () => {

    setLoading(true);

    userService.cancelSubscription(token.access_token).then(remoteResult => {

      if (remoteResult.status === 200) {
        setCancelConfirmOpen(false);
        setLoading(false);
        history.replace({
          pathname: '/subscription/cancel/successful',
          state: {
            'lastDate': history.location.state.lastDate,
          },
        });

      } else {
        setLoading(false);
        setOpenError(true);
      }

    }).catch((error) => {
      setLoading(false);
      setOpenError(true);
    });
  }

  const handleErrorClose = () => {
    setOpenError(false);
  }

  const handleContactSalesClose = () => {
    setContactSalesOpen(false);
  }

  return (
    <CommonLayout>
      <div className={classes.pageContainer}>
        <div className={classes.title1}>
          {t('subscription.cancelTitle')}
        </div>
        <div>
          <img src={cancelSubscriptionImg} alt="cancel subscription" className={classes.image}/>
        </div>

        <div className={classes.description}>
          {t('subscription.cancelSubscriptionDesc')}
        </div>

        <Button className={classes.submitButton} onClick={showCancelSubscriptionDialog}>
          {t('common.continue')}
        </Button>

      </div>

      <Dialog
        open={cancelConfirmOpen}
        onClose={handleCancelConfirmClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{t('subscription.unsubscribeDialogTitle')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t('subscription.unsubscribeDialogDesc').replace('{card_last_4}', history.location.state.cardLast4)}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelConfirmClose} className={classes.dialogText}>
            {t('common.later')}
          </Button>

          <div style={{position: 'relative'}}>
            <Button onClick={handleCancelSubscription} className={classes.dialogText} disabled={loading}>
              {t('common.confirm')}
            </Button>
            {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </div>
        </DialogActions>
      </Dialog>

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error">
          <span className={classes.alertTitle}>{t('subscription.failed')}</span>
        </Alert>
      </Snackbar>

      <Dialog
        open={contactSalesOpen}
        onClose={handleContactSalesClose}
        maxWidth={'sm'}
        fullWidth>
        <DialogTitle id="alert-dialog-title">{t('pricing.contactSales')}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <span>{t('pricing.contactSalesDesc')}</span>
            &nbsp;
            <span className={classes.dialogText}>sales@openuse.io</span>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleContactSalesClose} color="primary">
            <span className={classes.dialogText}>{t('pricing.gotIt')}</span>
          </Button>
        </DialogActions>
      </Dialog>

    </CommonLayout>
  );

}
