import './RegisterPage.style'
import {Typography} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  container: {
    padding: '12% 20%',
  },
  hintsText: {
    color: theme.currentTheme.colors[3],
    marginBottom: theme.spacing(5)
  },
}));

export default function RegisterSuccessPage() {
  const classes = useStyles();
  const {t} = useTranslation();


  return (
    <div className={classes.container}>
      <Typography variant='h2' gutterBottom>
        {t('register.pendingTitle')}
      </Typography>
      <Typography variant='h4' className={classes.hintsText}>
        {t('register.successContent1')}
      </Typography>
      <Typography variant='h6' gutterBottom>
        {t('register.successContent2')}
      </Typography>
      <Typography variant='h6' className={classes.hintsText}>
        {t('register.successContent3')}
      </Typography>
    </div>
  )
}
