const langCN = {
  lang: 'English',
  changeLang: "切换到 English",
  common: {
    ok: '确定',
    cancel: '取消',
    save: '保存',
    confirm:'确认',
    actions:'操作',
    delete: '删除',
    edit: '修改',
    noRecord: '没有数据显示',
    download:'下载',
    check:'检查',
    add:'添加',
    home:'首页',
    export:'导出',
    exportAsCsv:'保存成CSV文件',
    duplicate:'复制',
    sharePO:'分享账本',
    qrCode:'生成二维码',
    print:'打印',
    settings: '设置',
    all_selected: '全选',
    all_not_selected: '全取消',
    add_new_note: '$username 添加了新的备注。',
    review_notifications: '查看通知',
    no_notifications: '没有新的通知',
    mark_read: '确认已读',
    attach: '上传附件',
    downloadAttachment: '下载附件',
    noAttach: '没有附件下载',
    shareBook: '分享账本',
    bookDistribute: '账本分享给',
    supplier: '供应商',
    purchaser: '采购商',
    email: '邮件地址',
    continue: '继续',
    backHome: '回到主页',
    later: '以后再说',
    search: '搜索',
  },
  footer: {
    terms: '服务条款',
    privacy: '隐私声明',
  },
  login: {
    signIn: '登录',
    signOut: '登出',
    signUp: '注册',
    signUpEmail: '通过邮箱注册',
  },
  toolbar:{
    switchDark:'切换到暗主题',
    switchLight:'切换到亮主题',
    solutions:'方案',
    enterprise:'企业',
    pricing:'价目',
    profile: '我的',
    subscription: '订阅',
    security: '安全',
    logout: '退出登录',
    download: '下载',
  },
  countries: {
    cn: "中国",
    us: "美国",
    other: "其它"
  },
  register: {
    firstName: '名字*',
    firstNameMsg: '请输入您的名字',
    lastName: '姓氏*',
    lastNameMsg: '请输入您的姓氏',
    company: '公司',
    infoMsg2: '至少有8个字符',
    infoMsg3: '1个字母',
    infoMsg4: '1个数字',
    passMsg1: '请输入密码',
    passMsg2: '您输入的密码不符合要求',
    errorMsg3: '您输入的两次密码不匹配',
    successContent1: '很高兴您的加入',
    gotoSign: '将自动跳转到登录页面',
  },
  security: {
    oldPassword: '原来的密码*',
    newPassword: '新的密码*',
    rePassword: '确认密码*',
    change: '修改密码',
    changePassword: '修改密码',
    passwordChangedSuccess: '您的密码已修改',
    cannotChangePassword: '修改密码失败',
  },
  profile: {
    title: '我的资料',
    email: '注册邮箱',
    associatedEmail: '关联邮箱',
    phone: '电话',
    street: '街道',
    city: '城市',
    state: '省份',
    zip: '邮编',
    country: '国家',
    companyMsg: '请输入公司名称',
    phoneMsg: '请输入电话',
    uploadWarning1: '请上传(JPG/PNG)图片',
    uploadWarning2: '请上传1M以内的图片!',
    uploadError: '上传图片失败',
    addAvatar: '添加头像',
    addLogo: '添加标识',
    saveProfileSuccess: '您的资料已成功保存',
    saveProfileFailed: '无法保存您的资料，请稍后再试。',
  },
  pricing: {
    contactSales: '联系销售',
    contactSalesDesc: '请联系销售',
    gotIt: '明白了',
  },
  subscription: {
    title: '订阅',
    upgrade: '升级',
    cancelTitle: '取消订阅',
    plan: '方案',
    inactive: '停用',
    nextPayment: '下次付款时间',
    trial: '试用版',
    basic: '基础版',
    professional: '专业版',
    enterprise: '企业版',
    expiration: '到期',
    cancelSubscriptionDesc: '这将删除所有数据，包括发送和接收的报价申请，发送和接收的采购订单，存档的采购订单，您的供应商联系方式，等等。',
    unsubscribeDialogTitle: '确认取消',
    unsubscribeDialogDesc: '我们将通过您尾数为{card_last_4}的信用卡取消订阅服务。当月已支付的服务费不予退还，取消即时生效',
    cancelSubscriptionSuccessfulDesc: '您的订阅已成功取消，您仍可在{last_date}日前使用此订阅',
    invoices: '发票',
    view: '查看详细内容',
    searchInvoiceFailed: '资源服务器忙，请稍候重试',
    paymentTime: '付款时间',
    invoiceNumber: '账单编号',
    amountDue: '应付金额',
    startDate: '从',
    endDate: '到'
  }
}
export default langCN;
