import Toolbar from "@material-ui/core/Toolbar";
import OpenDaisLogo from "./OpenDaisLogo";
import {Button, IconButton} from "@material-ui/core";
import Box from "@material-ui/core/Box";
import PersonIcon from '@material-ui/icons/Person';
import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import ThemeToggle from "./ThemeToggle";
import {useState} from "react";
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Fade from '@material-ui/core/Fade';
import {useHistory} from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: theme.currentTheme.backgrounds[0],
    height: '58px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  iconButton: {
    color: '#FFFFFF'
  },
  selected: {
    borderBottom: '4px solid #FF5722',
  },
  avatarBox: {
    cursor: 'pointer'
  },
  menuButton: {
  },
  signBtn: {
    [theme.breakpoints.up('md')]: {
      width: '100px',
    },
  },
  signBtnTxt: {
    fontSize: '16px',
    fontFamily: 'RobotoRegular',
    letterSpacing: '0.08px',
    fontWeight: '600',
    color: theme.currentTheme.colors[0],
    '&:hover': {
      color: theme.currentTheme.colors[4],
    }
  },
  menuItem: {
    fontSize: '16px',
    fontFamily: 'RobotoRegular',
    letterSpacing: '0.08px',
  },
}));
export default function NormalHeader(props) {
  const classes = useStyles();
  const {t} = useTranslation();
  const {handlers, darkMode} = props;
  const history = useHistory();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const gotoProfilePage = () => {
    setAnchorEl(null);
    history.push('/profile');
  }

  const gotoSubscriptionPage = () => {
    setAnchorEl(null);
    history.push('/subscription');
  }

  const gotoSecurityPage = () => {
    setAnchorEl(null);
    history.push('/security');
  }

  const handleLogout = () => {
    setAnchorEl(null);
    handlers.signOutHandler();
  }

  return (
    <Toolbar className={classes.header} style={{boxShadow: 'white !important'}}>
      <OpenDaisLogo logoClick={handlers.jump2Home} darkMode={darkMode}></OpenDaisLogo>

      <div style={{marginLeft:'40px'}}>
        <Button className={classes.signBtn} onClick={handlers.jump2Solutions}>
          <span className={classes.signBtnTxt}>{t('toolbar.solutions')}</span>
        </Button>
        <Button className={classes.signBtn} onClick={handlers.jump2Enterprise}>
          <span className={classes.signBtnTxt}>{t('toolbar.enterprise')}</span>
        </Button>
        {props.token && <Button className={classes.signBtn} onClick={handlers.jump2Pricing}>
          <span className={classes.signBtnTxt}>{t('toolbar.pricing')}</span>
        </Button>}
      </div>

      <Box sx={{ flexGrow: 1 }} />

      <ThemeToggle />

      {
        !props.token &&
        <Button className={classes.signBtn} onClick={handlers.signInHandler}>
          <span className={classes.signBtnTxt}>{t('login.signIn')}</span>
        </Button>
      }

      {
        !props.token &&
        <Button variant={'contained'} color="primary" className={classes.signBtn} style={{borderRadius: '28px',}} onClick={handlers.signUpHandler}>
          <span className={classes.signBtnTxt} style={{color: 'white'}}>{t('login.signUp')}</span>
        </Button>
      }

      {
        props.token &&

        <IconButton className={classes.iconButton} onClick={handleMenuOpen}>
          <PersonIcon />
        </IconButton>
      }

      <Menu
        style={{marginTop: '35px'}}
        id="fade-menu"
        anchorEl={anchorEl}
        keepMounted
        open={open}
        onClose={handleMenuClose}
        TransitionComponent={Fade}>

        <MenuItem className={classes.menuItem} onClick={gotoProfilePage}>{t('toolbar.profile')}</MenuItem>
        <MenuItem className={classes.menuItem} onClick={gotoSubscriptionPage}>{t('toolbar.subscription')}</MenuItem>
        <MenuItem className={classes.menuItem} onClick={gotoSecurityPage}>{t('toolbar.security')}</MenuItem>
        <MenuItem className={classes.menuItem} onClick={handleLogout}>{t('toolbar.logout')}</MenuItem>

      </Menu>

    </Toolbar>
  );
}
