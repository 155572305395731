import {makeStyles} from "@material-ui/core/styles";
import {useTranslation} from "react-i18next";
import CommonLayout from "../../components/CommonLayout";
import React, {useEffect, useState} from "react";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {Backdrop, Button, CircularProgress, List, ListItem, Snackbar} from "@material-ui/core";
import userService from "../../services/userService";
import useToken from "../../hooks/useToken";
import FormFunction from "./FormFunction";
import moment from "moment";
import {useHistory} from "react-router-dom";
import LocalEncrypt from "../../utils/encrypt";
import {Alert, Skeleton} from "@material-ui/lab";
import {Result} from "antd";

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    paddingBottom: '80px',
    paddingTop: '20px',
    backgroundColor: theme.currentTheme.background,
    [theme.breakpoints.down('sm')]: {
      padding: '50px',
    },
    [theme.breakpoints.down('xs')]: {
      padding: '20px 20px 50px 20px',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  title1: {
    fontSize: '36px',
    fontFamily: 'RobotoRegular',
    '@media (max-width:600px)': {
      fontSize: '22px',
    },
    fontWeight: '300',
    textAlign: 'center',
    marginBottom: '50px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#127bb0',
  },
  submitButton: {
    width: '460px',
    height: '48px !important',
    marginTop: '30px',
    fontFamily: 'RobotoRegular',
    fontSize: '16px',
    '@media (max-width:600px)':  {
      width: '100%',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    paddingTop: '15px',
    paddingBottom: '15px',
  },
  rowButton: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'end',
    alignItems: 'center',
  },
  root: {
    width: '460px',
    border: theme.currentTheme.border['0'],
    borderRadius: '8px',
    paddingTop: 0,
    paddingBottom: 0,
    '@media (max-width:600px)':  {
      width: '100%',
    },
  },
  resultText: {
    color: theme.currentTheme.colors[0],
  },
  skeleton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    width: '460px',
    '@media (max-width:600px)':  {
      width: '100%',
    },
  },
}));

export default function Subscription() {

  const {t} = useTranslation();
  const classes = useStyles();
  const {token} = useToken();

  const [openBackdrop, setOpenBackdrop] = useState(true);
  const {fields} = FormFunction();
  const [paymentStatus, setPaymentStatus] = useState('');
  const [openError, setOpenError] = useState(false);
  const [serverError, setServerError] = useState(false);
  const history = useHistory();

  useEffect(() => {

    setOpenBackdrop(true);

    userService.getPaymentStatus(token['access_token']).then((remoteResult) => {

      console.log('remoteResult.data: ', remoteResult.data);
      if (remoteResult.status === 200) {

        if (!remoteResult.data || Object.getOwnPropertyNames(remoteResult.data).length === 0) {
          console.log('remoteResult.data is empty');
          setPaymentStatus('free');
          fields.paymentType.value = t('subscription.trial');
        }

        fields.active.value = remoteResult.data.active;
        fields.invoice.value = remoteResult.data.invoice;
        fields.cardLast4.value = remoteResult.data.cardLast4;

        if (remoteResult.data.paymentType === 'Free' || remoteResult.data.paymentType === 'Initial') {
          setPaymentStatus('free');
          fields.paymentType.value = t('subscription.trial');
        } else if (remoteResult.data.paymentType === 'Basic') {
          setPaymentStatus('basic');
          if (fields.active.value) {
            fields.paymentType.value = t('subscription.basic');
            fields.nextPaymentTile.value = t('subscription.nextPayment');
          } else {
            fields.paymentType.value = t('subscription.basic') + ' (' + t('subscription.inactive') + ')';
            fields.nextPaymentTile.value = t('subscription.expiration');
          }

          fields.periodEnd.value = moment(remoteResult.data.periodEnd * 1000).local().format('YYYY-MM-DD');
        } else if (remoteResult.data.paymentType === 'Professional') {
          setPaymentStatus('professional');
          if (fields.active.value) {
            fields.paymentType.value = t('subscription.professional');
            fields.nextPaymentTile.value = t('subscription.nextPayment');
          } else {
            fields.paymentType.value = t('subscription.professional') + ' (' + t('subscription.inactive') + ')';
            fields.nextPaymentTile.value = t('subscription.expiration');
          }
          fields.periodEnd.value = moment(remoteResult.data.periodEnd * 1000).local().format('YYYY-MM-DD');
        } else if (remoteResult.data.paymentType === 'Enterprise') {
          setPaymentStatus('enterprise');
          if (fields.active.value) {
            fields.paymentType.value = t('subscription.enterprise');
            fields.nextPaymentTile.value = t('subscription.nextPayment');
          } else {
            fields.paymentType.value = t('subscription.enterprise') + ' (' + t('subscription.inactive') + ')';
            fields.nextPaymentTile.value = t('subscription.expiration');
          }
          fields.periodEnd.value = moment(remoteResult.data.periodEnd * 1000).local().format('YYYY-MM-DD');
        }

      } else {
        setOpenError(true);
        setServerError(true);
      }

      setOpenBackdrop(false);

    }).catch((_) => {
      setOpenBackdrop(false);
      setOpenError(true);
      setServerError(true);
    });

  }, []);

  const gotoPricing = () => {

    if (paymentStatus !== 'enterprise') {
      const params = {
        lang: 'en',
        paymentType: (paymentStatus && fields.active.value) ? paymentStatus : 'free',
        cardLast4: fields.cardLast4.value ? fields.cardLast4.value : '',
      };

      let url = process.env.REACT_APP_PAYMENT_URL + '/rfq-payment/home?';
      url += 'token=' + token['access_token'] + '&params=' + LocalEncrypt(JSON.stringify(params));
      window.location.href = url;
    }

  }

  const gotoInvoice = () => {

    history.push({
      pathname: '/subscription/invoice',
      state: {
        invoices: fields.invoice.value,
      }
    })

  }

  const gotoCancelSubscription = () => {
    history.push({
      pathname: '/subscription/cancel',
      state: {
        'paymentStatus': paymentStatus,
        'cardLast4': fields.cardLast4.value,
        'lastDate': fields.periodEnd.value,
      },
    });
  }

  const handleErrorClose = () => {
    setOpenError(false);
  }

  return (

    <CommonLayout>

      <div className={classes.pageContainer}>

        <div className={classes.title1}>
          {t('subscription.title')}
        </div>

        {
          !serverError && openBackdrop &&
          <div className={classes.skeleton}>
            <Skeleton animation="wave" height={26} width="38%"  />
            <Skeleton animation="wave" height={26} width="100%" style={{ marginTop: 10 }} />
            <Skeleton animation="wave" height={26} width="100%" style={{ marginTop: 6 }}/>
            <Skeleton animation="wave" height={26} width="62%" style={{ marginTop: 6 }}/>
          </div>
        }

        {
          !serverError && !openBackdrop &&
          <List component="nav" className={classes.root} aria-label="mailbox folders">
            <ListItem button={paymentStatus !== 'enterprise'} divider className={classes.row} onClick={gotoPricing}>
              <div>{t('subscription.plan')}</div>
              <div className={classes.rowButton}>
                <div>{fields.paymentType.value}</div>
                {
                  paymentStatus !== 'enterprise' && <ArrowForwardIosIcon/>
                }
              </div>
            </ListItem>
            {
              paymentStatus && (paymentStatus === 'basic' || paymentStatus === 'professional' || paymentStatus === 'enterprise') &&
              <ListItem divider className={classes.row}>
                <div>{fields.nextPaymentTile.value}</div>
                <div>{fields.periodEnd.value}</div>
              </ListItem>
            }

            {
              <ListItem button
                        divider={paymentStatus === 'basic' || paymentStatus === 'professional' || paymentStatus === 'enterprise'}
                        className={classes.row} onClick={gotoInvoice}>
                <div>{t('subscription.invoices')}</div>
                <ArrowForwardIosIcon/>
              </ListItem>

            }

            {
              fields.active.value && (paymentStatus === 'basic' || paymentStatus === 'professional' || paymentStatus === 'enterprise') &&

              <ListItem button className={classes.row} onClick={gotoCancelSubscription}>
                <div>{t('subscription.cancelTitle')}</div>
                <ArrowForwardIosIcon/>
              </ListItem>
            }


          </List>
        }

        {
          !serverError && !openBackdrop && (paymentStatus === 'free' || paymentStatus === 'basic' || paymentStatus === 'professional') &&
          <Button className={classes.submitButton} variant="contained" color='primary' onClick={gotoPricing}>
            {t('subscription.upgrade')}
          </Button>
        }

      </div>

      {
        serverError &&
        <Result
          style={{marginTop: '-100px'}}
          status="500"
          title={<span className={classes.resultText}>500</span>}
          subTitle={<span className={classes.resultText}>{t('common.failed')}</span>}
          extra={<Button type="primary" variant="contained" color='primary' onClick={() => history.push('/')}>{t('common.backHome')}</Button>}
        />
      }

      <Backdrop className={classes.backdrop} open={openBackdrop}>
        <CircularProgress color="inherit"/>
      </Backdrop>

      <Snackbar open={openError} autoHideDuration={6000} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error">
          <span className={classes.alertTitle}>{t('common.failed')}</span>
        </Alert>
      </Snackbar>

    </CommonLayout>

  );

}
