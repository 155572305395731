import axios from 'axios';

const loginURLInst = function () {
    let iamUrl = process.env.REACT_APP_IAM_URL;
    if(process.env.REACT_APP_IAM_CLIENT_ID && process.env.REACT_APP_IAM_REDIRECT_URI && process.env.REACT_APP_IAM_RESPONSE_TYPE){
        iamUrl += '?client_id=';
        iamUrl += process.env.REACT_APP_IAM_CLIENT_ID;
        iamUrl += '&redirect_uri=';
        iamUrl += process.env.REACT_APP_IAM_REDIRECT_URI;
        iamUrl += '&response_type=';
        iamUrl += process.env.REACT_APP_IAM_RESPONSE_TYPE;
    }
    console.info('redirect to iam service url=' + iamUrl);
    return iamUrl;
}

const requestHandler = request => {
    const tokenString = sessionStorage.getItem('token');
    const token = JSON.parse(tokenString);
    if (token) {
        if (request.headers.Authorization === undefined || request.headers.Authorization === '') {
            request.headers['Authorization'] = "opendias " + token.access_token
        }
    }
    if (request.method.toLowerCase() === 'get') {
        if (!request.headers['Cache-Control']) {
            request.headers['Cache-Control'] = "no-cache";
        }
        if (!request.headers['Pragma']) {
            request.headers['Pragma'] = "no-cache";
        }
    }
    return request;
};

const responseHandler = response => {
    return response;
};

const errorHandler = error => {
    console.error(error);
    if (error.response && error.response.status && error.response.status + "" === '403') {
      window.sessionStorage.clear();
      window.localStorage.setItem('access_url',window.location.href);
      window.location.href = loginURLInst();
    }
    return Promise.reject(error);
};

const iamInst = axios.create({
    baseURL: process.env.REACT_APP_IAM_SERVICE_URL
});
iamInst.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);
iamInst.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

const coreInst = axios.create({
    baseURL: process.env.REACT_APP_API_BASE_URL
});
coreInst.interceptors.request.use(
    (request) => requestHandler(request),
    (error) => errorHandler(error)
);
coreInst.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
);

export const getLoginURL = loginURLInst;
export const axiosIam = iamInst;
export const axiosCore = coreInst;

