const darkTheme = {
    headerBackground: '#000000',
    background: '#000000',
    text: '#ffffff',
    backgrounds: [
        '#000000',
        '#000000',
        '#ffffff',
        '#000000',
        '#848484',
        "#424242",
        "#000000",
        "rgb(0, 30, 60)",
        "#127bb0",
        "#000000",
        "rgb(0, 30, 60)",
        "rgb(19,47,76)",
        "rgb(0, 30, 60)",
        "#001e3b"
    ],
    theme2backgrounds:[
        "rgb(19,47,76)",
        "RGB(0,30,60)",
        "RGB(19,47,76)",
        "RGB(19,47,76)",
        "rgba(0, 0, 0, 0.1)"
    ],
    colors: {
        '0': '#FFFFFF',
        '1': '#000000',
        '2': '#ffffff',
        '3': '#6e6e6e',
        '4': '#a6a6a6',
        '5': '#7f7f7f',
        '6': '#7f7f7f',
        '7': '#6db2e8',
        '8': '#ffffff',
        '9': '#6db2e8',
    },
    border: {
        '0': 'solid 1px #1e4976',
        '1': 'solid 1px #eaeaea',
        '2': 'solid 1px #7f7f7f',
        '3': 'solid 1px #1e4976',
    },
}
export default darkTheme;
